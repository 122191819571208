import React, { useContext, useEffect, useState } from 'react';
import { Avatar, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CustomProvider } from 'app/layouts/vertical-default/VerticalDefault';

export default function CustomerTable(props) {
	const { t } = useTranslation();
	const { loginUserData } = useContext(CustomProvider);
	const [refRevenue, setRefRevenue] = useState({ per1: 0, per2: 0 });
	const { refferedUsers, totalUsersCount } = props?.refUsers || {};

	let showStatus = true;
	if (props.hasOwnProperty('showStatus')) {
		showStatus = props.showStatus;
	}
	const [currentPage, setCurrentPage] = useState(1);
	const [currentUsers, setCurrentUsers] = useState([]);
	const itemsPerPage = 5;
	const totalPages = Math.ceil(refferedUsers?.length / itemsPerPage);

	const indexOfLastUser = currentPage * itemsPerPage;
	const indexOfFirstUser = indexOfLastUser - itemsPerPage;

	const color = {
		"In trial": "#2c73ff",
		"Active": "#22C55E",
		"Paid": "#22C55E",
		"To Be Collected": "#FFC804",
		"Payment Due": "#FF7A00",
		"Cancelled": "#FF0000"
	};

	const formatDate = (dateInput, isUnix = false) => {
		const date = isUnix ? new Date(dateInput * 1000) : new Date(dateInput);
		return date.toLocaleDateString('en-US', {
			year: 'numeric',
			month: 'long',
			day: 'numeric',
		});
	};

	const calcRevenue = () => {
		const count = totalUsersCount?.[0]?.totalCount || 0;
		let revenue = { per1: 0, per2: 0 };

		if (count >= 1 && count < 3) revenue = { per1: 20, per2: 0 };
		else if (count >= 3 && count < 10) revenue = { per1: 20, per2: 20 };
		else if (count >= 10 && count < 20) revenue = { per1: 22.5, per2: 22.5 };
		else if (count >= 20 && count < 30) revenue = { per1: 25, per2: 25 };
		else if (count >= 30 && count < 50) revenue = { per1: 30, per2: 25 };
		else if (count >= 50) revenue = { per1: 30, per2: 30 };
		
		setRefRevenue(revenue); 
	};

	const calEstRevenue = (user, revenue) => {
		if (!user || !revenue) return 0; // If user or revenue is not defined, return 0
		if (Number(user.sponsorid) === loginUserData?.user_id) {
			// Level 1 user: calculate revenue based on per1
			return (user.plan_price * revenue.per1) / 100 || 0;
		} else if (Number(user.l2_sponsorid) === loginUserData?.user_id) {
			// Level 2 user: calculate revenue based on per2
			return (user.plan_price * revenue.per2) / 100 || 0;
		}
		return 0; // If no matching level, return 0
	};

	useEffect(() => {
		if (totalUsersCount?.[0]?.totalCount) {
			calcRevenue();
		}
	}, [totalUsersCount]);

	useEffect(() => {
		setCurrentUsers(() => refferedUsers?.slice(indexOfFirstUser, indexOfLastUser));
	}, [currentPage, refferedUsers]);

	const paginate = (pageNumber) => setCurrentPage(pageNumber);
	console.log("current", currentUsers)

	return (
		<>
			<TableContainer component={Paper} elevation={3} sx={{ borderRadius: '8px', overflow: 'hidden', margin: '20px 0' }}>
				<Table className='sep-AffiliateTable-main' aria-label="customer table">
					<TableHead>
						<TableRow sx={{ backgroundColor: '#f4f6f8' }}>
							<TableCell><Typography variant="subtitle2">#</Typography></TableCell>
							<TableCell><Typography variant="subtitle2">Name</Typography></TableCell>
							<TableCell align="center"><Typography variant="subtitle2">Plan</Typography></TableCell>
							<TableCell align="center"><Typography variant="subtitle2">Period</Typography></TableCell>
							<TableCell align="center"><Typography variant="subtitle2">Price</Typography></TableCell>
							<TableCell align="center"><Typography variant="subtitle2">Estimated Revenue</Typography></TableCell>
							<TableCell align="center"><Typography variant="subtitle2">Joining Date</Typography></TableCell>
							<TableCell align="center"><Typography variant="subtitle2">Activation on</Typography></TableCell>
							<TableCell align="center"><Typography variant="subtitle2">Sponsored By</Typography></TableCell>
							<TableCell align="center"><Typography variant="subtitle2">Status</Typography></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{!currentUsers?.length ? (
							<TableRow>
								<TableCell colSpan={10} align="center" sx={{ padding: '16px' }}>
									<Typography variant="body1" color="textSecondary">{t("pages.title.Noanynewsaleincurrentmonth")}</Typography>
								</TableCell>
							</TableRow>
						) : (
							currentUsers.map((userData, index) => (
								<TableRow
									key={`${userData?.customerid}-${index}`}
									sx={{
										backgroundColor: index % 2 === 0 ? '#f6f6f6' : 'inherit',
										'&:hover': { backgroundColor: '#f1f3f5' },
										transition: 'background-color 0.3s ease',
									}}
								>
									<TableCell align="left">{indexOfFirstUser + index + 1}</TableCell>
									<TableCell align="left">
										<div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
											<Avatar alt="Avatar" sx={{ width: 40, height: 40 }} />
											<div>
												<Typography variant="body2" sx={{ fontWeight: '500' }}>{`${userData?.firstname} ${userData?.lastname}`}</Typography>
												<Typography variant="body2" sx={{ color: '#888', fontSize: '0.65rem' }}>
													{Number(userData?.sponsorid) === loginUserData?.user_id ? userData?.email || ' ' : ' '}
												</Typography>
											</div>
										</div>
									</TableCell>
									<TableCell align="center">{userData?.plan_pkg === "Unlimited_new" ? "Unlimited" : userData?.plan_pkg}</TableCell>
									<TableCell align="center">{(userData?.sub_type === "year" ? 12 : userData?.plan_period) + (userData?.plan_period === 1 && userData?.sub_type === "month" ? " Month" : " Months")}</TableCell>
									<TableCell align="center">{`${userData?.plan_price} ${userData?.currency}`}</TableCell>
									<TableCell align="center">
										{`${calEstRevenue(userData, refRevenue).toFixed(2)} ${userData?.currency === "USD" ? "$" : "€"}`}
									</TableCell>
									<TableCell align="center">{formatDate(userData?.createdat)}</TableCell>
									<TableCell align="center">{userData?.trial_end === 0 ? formatDate(userData?.createdat) : formatDate(userData?.trial_end, true)}</TableCell>
									<TableCell align="center">
										{Number(userData?.sponsorid) === loginUserData?.user_id ?  'You' : ' '}
									</TableCell>
									<TableCell align="center">
										{userData?.trial_status === 'Active' ? (
											<Chip label="In trial" sx={{ backgroundColor: color["In trial"], color: '#fff' }} />
										) : (
											<Chip label={userData?.subscription_status} sx={{
												backgroundColor: color[userData?.subscription_status] || '#ccc',
												color: '#fff',
												width: '100px',
												textAlign: 'center',
											}} />
										)}
									</TableCell>
								</TableRow>
							))
						)}
						{/* {props.customersInfo.map((row, index) => (
                        <TableRow
                            key={row.name}
                            sx={{
                                '&:last-child td, &:last-child th': { border: 0 },
                                backgroundColor: index % 2 === 0 ? '#F6F6F6' : 'inherit' // alternate row color
                            }}
                        >
                            <TableCell align='left' sx={{color: "#919EAB", fontSize: "14px", lineHeight: "18px", fontFamily: "'DM Sans'", fontWeight: "500"}}>
                                <span style={{display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "10px"}}>
                                    <Avatar alt="Avatar Image" src={row.image} sx={{ width: 30, height: 30 }} />
                                    {row.name}
                                </span>
                            </TableCell>
                            <TableCell align="left" sx={{color: "#919EAB", fontSize: "14px", lineHeight: "18px", fontFamily: "'DM Sans'", fontWeight: "500"}}>{row.email}</TableCell>
                            <TableCell align="center" sx={{color: "#919EAB", fontSize: "14px", lineHeight: "18px", fontFamily: "'DM Sans'", fontWeight: "500"}}>{row.plan}</TableCell>
                            <TableCell align="center" sx={{color: "#919EAB", fontSize: "14px", lineHeight: "18px", fontFamily: "'DM Sans'", fontWeight: "500"}}>{row.price}</TableCell>
                            <TableCell align="center" sx={{color: "#919EAB", fontSize: "14px", lineHeight: "18px", fontFamily: "'DM Sans'", fontWeight: "500"}}>{row.joiningDate}</TableCell>
                            <TableCell align="center" sx={{color: "#919EAB", fontSize: "14px", lineHeight: "18px", fontFamily: "'DM Sans'", fontWeight: "500"}}>{row.nextPayment}</TableCell>
                            <TableCell align="center" sx={{color: "#919EAB", fontSize: "14px", lineHeight: "18px", fontFamily: "'DM Sans'", fontWeight: "500"}}>{row.sponsoredBy}</TableCell>
                            {
                                showStatus &&
                                <TableCell align="center">
                                    <span style={{
                                        background: color[row.status],
                                        padding: "3px 11px",
                                        borderRadius: "6px",
                                        color: "#fff",
                                        display: "inline-flex",
                                        width: "140px",
                                        justifyContent: "center"
                                    }}>{row.status}</span>
                                </TableCell>
                            }
                        </TableRow>
                    ))} */}
					</TableBody>
				</Table>
			</TableContainer>
			<CustomerTablePagination
				skip={indexOfFirstUser}
				limit={itemsPerPage}
				page={currentPage}
				totalPages={totalPages}
				onPageChange={paginate}
			/>
		</>
			
	);
}

const CustomerTablePagination = ({ page, totalPages, onPageChange }) => {

	// if (totalPages === 1) return null   // uncomment this if we want to show pagination on single page

	const handleNext = () => {
		if (page < totalPages) onPageChange(page + 1);
	};

	const handlePrev = () => {
		if (page > 1) onPageChange(page - 1);
	};

	const handlePageClick = (pageNumber) => {
		onPageChange(pageNumber);
	};

	const renderPageNumbers = () => {
		const pages = [];
		if (totalPages <= 10) {
			for (let i = 1; i <= totalPages; i++) {
				pages.push(i);
			}
		} else {
			pages.push(1, 2);
			if (page > 4) pages.push('...');
			const start = Math.max(3, page - 2);
			const end = Math.min(totalPages - 2, page + 2);
			for (let i = start; i <= end; i++) {
				pages.push(i);
			}
			if (page < totalPages - 3) pages.push('...');
			pages.push(totalPages - 1, totalPages);
		}
		return pages;
	};

	const btnStyle = {
		backgroundColor: "#fff",
		color: "grey",
		padding: "5px 15px", 
		margin: "0 5px", 
		borderRadius: "5px", 
	}
	return (
		<div style={{ padding: '10px', display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
			<button
				onClick={handlePrev}
				disabled={page === 1}
				style={btnStyle}
			>
				{'<'}
			</button>

			{renderPageNumbers().map((pageNumber, index) => (
				<button
					key={index}
					onClick={() => pageNumber !== '...' && handlePageClick(pageNumber)}
					disabled={pageNumber === page || pageNumber === '...'}
					style={{
						...btnStyle, 
						backgroundColor: pageNumber === page ? '#2c73ff' : '#f4f5f5',
						color: pageNumber === page ? 'white' : 'black',
						cursor: pageNumber === '...' ? 'default' : 'pointer',
					}}
				>
					{pageNumber}
				</button>
			))}

			<button
				onClick={handleNext}
				disabled={page === totalPages}
				style={btnStyle}
			>
				{'>'}
			</button>
		</div>
	);
};
