import jwtAxios from "axios";

const jwtAuthAxios = jwtAxios.create({
  // baseURL: "http://localhost:8000/user/api","https://stagingbackend.novalya.com/"
  baseURL: "https://stagingbackend.novalya.com/user/api",
  // baseURL: "https://stagingbackend.novalya.com/user/api",
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

jwtAuthAxios.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response && err.response.data.type === "token-invalid") {
      //todo logout the user
    }
    return Promise.reject(err);
  }
);

//todo: define interceptors and other configuration like baseURL, headers etc. here
export default jwtAuthAxios;
