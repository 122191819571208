import React, { useContext, useEffect, useState } from "react";
import AffiliateLayout from "./common/AffiliateLayout";
import {
  Button,
  Card,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { getunilevelreports } from "backendServices/ApiCalls";
import { BsFunnel, BsSearch } from "react-icons/bs";
import Div from "@jumbo/shared/Div";
import { useNavigate } from "react-router-dom";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";

export default function AffiliateLevelCommission() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [unilevelbonus, setUnilevelBonusData] = useState([]);
  const currentMonth = new Date().getMonth() + 1;
 
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);

  const { loginUserData, setloginUserData } = useContext(CustomProvider);
  const navigate = useNavigate();
  if (loginUserData?.user_type === 'Normal') {
    navigate("/affiliate");}

 

    const months = [
      { label: "January", value: 1 },
      { label: "February", value: 2 },
      { label: "March", value: 3 },
      { label: "April", value: 4 },
      { label: "May", value: 5 },
      { label: "June", value: 6 },
      { label: "July", value: 7 },
      { label: "August", value: 8 },
      { label: "September", value: 9 },
      { label: "October", value: 10 },
      { label: "November", value: 11 },
      { label: "December", value: 12 }
    ];
    

  const UnilevelData = () => {
    let params = {
      month: selectedMonth,
    };
  
    setLoading(true);
    getunilevelreports(
      params,
      (response) => {
        setLoading(false);
        setUnilevelBonusData(response?.data?.data);
      },
      (error) => {
        setLoading(false);
        console.log(error?.response?.data);
      }
    );
  };

  useEffect(() => {
    UnilevelData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMonth]);

  const handleChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const formatDate = (dateInput) => {
    let date;
    date = new Date(dateInput); // Handle ISO 8601 format
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };



  if (loading) {
    return (
      <Div
        sx={{
          display: "flex",
          minWidth: 0,
          alignItems: "center",
          alignContent: "center",
          height: "100%",
        }}
      >
        <CircularProgress sx={{ m: "-40px auto 0" }} />
      </Div>
    );
  }

  return (
    <AffiliateLayout>
      <div className="title-area">
        <div className="title-area-left">
          <h2>Level Commission</h2>
        </div>
      </div>
      {loading && (
        <Stack alignItems="center">
          <CircularProgress />
        </Stack>
      )}
      {!loading && (
        <Card sx={{ padding: "20px" }}>
          <div
            className="title-area"
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "30px",
              marginBottom: "20px",
            }}
          >
            <div className="title-area-left">
              <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
                <InputLabel htmlFor="search">Search</InputLabel>
                <OutlinedInput
                  id="search"
                  type="text"
                  startAdornment={
                    <InputAdornment position="center">
                      <IconButton edge="end">
                        <BsSearch />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Search"
                />
              </FormControl>
            </div>
            <div
              className="title-area-right"
              style={{
                gap: "15px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FormControl sx={{ width: "150px" }}>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedMonth}
                  onChange={handleChange}
                >

                {
                  
                  months.map((item) => (
                    <MenuItem style={{background: selectedMonth === item.value?"#2C73FF":"",color:selectedMonth === item.value&&"white"}} value={item.value}>{item.label}</MenuItem>
                  ))
                } 
                </Select>
              </FormControl>
             
            </div>
          </div>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead sx={{ backgroundColor: "#E0E4E8" }}>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Currency</TableCell>
                  <TableCell align="left">Sender</TableCell>
                  <TableCell align="center">Type</TableCell>
                  <TableCell align="center">% Payout</TableCell>
                  <TableCell align="center">Date</TableCell>
                  {/* <TableCell align="center">Time</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {unilevelbonus.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      No Record Found
                    </TableCell>
                  </TableRow>
                )}
                {unilevelbonus.map((row, index) => (
                  <TableRow
                    key={row.sender}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      backgroundColor: index % 2 === 0 ? "#F6F6F6" : "inherit", // alternate row color
                    }}
                  >
                    <TableCell
                      align="left"
                      sx={{
                        color: '#919EAB',
                        fontSize: "14px",
                        lineHeight: "18px",
                        fontFamily: "'DM Sans'",
                        fontWeight: "500",
                      }}
                    >
                      {index + 1}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        color: "#919EAB",
                        fontSize: "14px",
                        lineHeight: "18px",
                        fontFamily: "'DM Sans'",
                        fontWeight: "500",
                      }}
                    >
                      {row.amount}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        color: "#919EAB",
                        fontSize: "14px",
                        lineHeight: "18px",
                        fontFamily: "'DM Sans'",
                        fontWeight: "500",
                      }}
                    >
                      {row.currency}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        color: "#919EAB",
                        fontSize: "14px",
                        lineHeight: "18px",
                        fontFamily: "'DM Sans'",
                        fontWeight: "500",
                      }}
                    >
                      {row.username}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: "#919EAB",
                        fontSize: "14px",
                        lineHeight: "18px",
                        fontFamily: "'DM Sans'",
                        fontWeight: "500",
                      }}
                    >
                      {row.type}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: "#919EAB",
                        fontSize: "14px",
                        lineHeight: "18px",
                        fontFamily: "'DM Sans'",
                        fontWeight: "500",
                      }}
                    >
                      {row.payOutPer}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: "#919EAB",
                        fontSize: "14px",
                        lineHeight: "18px",
                        fontFamily: "'DM Sans'",
                        fontWeight: "500",
                      }}
                    >
                      {row.createdat && formatDate(row.createdat)}
                    </TableCell>
                    {/* <TableCell
                      align="center"
                      sx={{
                        color: "#919EAB",
                        fontSize: "14px",
                        lineHeight: "18px",
                        fontFamily: "'DM Sans'",
                        fontWeight: "500",
                      }}
                    >
                      {row.time && formatTime(row.time)}
                    </TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      )}
    </AffiliateLayout>
  );
}
