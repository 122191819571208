import React, { useContext, useEffect, useState } from "react";
import "./CustomSidebar.css";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import LocalizationOptions from "app/shared/JumboCustomizer/components/LocalizationOptions";
import { IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import { AccountCircle } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import useJumboLayoutSidebar from "@jumbo/hooks/useJumboLayoutSidebar";

const CustomSidebar = () => {
  const location = useLocation();
  const { sidebarOptions, sideBarOpen } = useJumboLayoutSidebar();
  const isOpen = sideBarOpen;
  const { loginUserData } = useContext(CustomProvider);
  const currentRoute = location.pathname;
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();

  const [hideMenu, setHideMenu] = useState(
    loginUserData?.parent_id ? true : false
  );

  const affiliateLinks = ["/affiliate", "/affiliate/links", "/affiliate/all-customers", "/affiliate/level-commission","/affiliate/payments", "/affiliate/settings"]
  
  if (!affiliateLinks.includes(currentRoute) && currentRoute.includes("affiliate")) {
    navigate("/affiliate"); // Navigate to /affiliate
  }

 


  useEffect(() => {
    
    if (!loginUserData || !loginUserData?.isChatActive) return; // Return early if loginUserData is not available

    const userId = loginUserData.user_id || '';
    const userEmail = loginUserData.email || '';
    const firstName = loginUserData.firstname || '';
    const lastName = loginUserData.lastname || '';
    const full_name = firstName + ' ' + lastName;

    const script = document.createElement("script");
    script.type = "text/javascript";
    script.id = "zsiqscript";
    script.defer = true;
    script.src = "https://salesiq.zohopublic.eu/widget";

    console.log('userId---51', userId);

    const zohoScript = document.createElement("script");
    zohoScript.type = "text/javascript";
    zohoScript.id = "zsiqchat";
    zohoScript.innerHTML = `
      var $zoho = $zoho || {};
      $zoho.salesiq = $zoho.salesiq || {
        widgetcode: "siq5f4c925cb228d0b940cabb835a928bcc6de8d08633d7829fcca70ef0f97e6b50",
        values: {},
        ready: function() {
          $zoho.salesiq.visitor.id("${userId}");
          $zoho.salesiq.visitor.uniqueuserid("${userId}");
          $zoho.salesiq.visitor.email("${userEmail}");
          $zoho.salesiq.visitor.name("${full_name}");
        }
      };
    `;


    document.body.appendChild(zohoScript);
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
      document.body.removeChild(zohoScript);
    };
    
  }, [loginUserData]);
  

  useEffect(() => {
    if (loginUserData?.parent_id) {
      setHideMenu(true);
    } else {
      setHideMenu(false);
    }
  }, [loginUserData]);
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const removeScripts = () => {
    const chatWidget = document.querySelector('.zsiq_floatmain');
    if (chatWidget) {
      chatWidget.remove();
    }
  };

  const handleLogout = () => {
    const lang = localStorage.getItem("selectedLocale");
    const lastClosedDate = localStorage.getItem("popupClosedDate");
    sessionStorage.clear();
    localStorage.clear();
    reInitializeBrevo();
    removeAllCookies();
    localStorage.setItem("selectedLocale", lang || "en-US");
    localStorage.setItem("popupClosedDate", lastClosedDate);
    removeScripts(); 
    navigate("/login");
    setAnchorEl(null);
  };

  const reInitializeBrevo = () => {
    if (window.sib && window.sendinblue) {
      // Reinitialize Brevo if necessary
      window.sib.equeue = [];
      window.sendinblue.identify = function () {};
      window.sendinblue.page();
    }
  };

  const removeAllCookies = () => {
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
    }
  };
  return (
    <>
      <Tooltip title={!isOpen ? "Profile" : ""} placement="right" arrow>
        <IconButton
          className="sidebar-user-btn"
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenu}
          color="inherit"
          sx={{
            padding: isOpen ? "4px !important" : "10px !important",
            width: isOpen ? "calc(100% - 20px)" : "auto",
          }}
        >
          <div className="side-name-menu">
            <AccountCircle />

            <p style={{ display: isOpen ? "block" : "none" }}>
              {loginUserData?.firstname}
            </p>
            <svg
              className="side-drop-arrow"
              width="37"
              height="36"
              viewBox="0 0 37 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ display: isOpen ? "block" : "none" }}
            >
              <path
                d="M36.5 18C36.5 27.6522 28.4541 35.5 18.5 35.5C8.54587 35.5 0.5 27.6522 0.5 18C0.5 8.34784 8.54587 0.5 18.5 0.5C28.4541 0.5 36.5 8.34784 36.5 18Z"
                fill="#2c73ff"
                stroke="#ECECEC"
              />
              <path
                d="M23.6389 17L18.5 22L13.3611 17"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            navigate("/profile");
            setAnchorEl(null);
          }}
          className="profile-go"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M23 17.9231V21.3077C23 21.7565 22.8217 22.187 22.5043 22.5043C22.187 22.8217 21.7565 23 21.3077 23H17.9231M17.9231 1H21.3077C21.7565 1 22.187 1.1783 22.5043 1.49567C22.8217 1.81303 23 2.24348 23 2.69231V6.07692M1 6.07692V2.69231C1 2.24348 1.1783 1.81303 1.49567 1.49567C1.81303 1.1783 2.24348 1 2.69231 1H6.07692M6.07692 23H2.69231C2.24348 23 1.81303 22.8217 1.49567 22.5043C1.1783 22.187 1 21.7565 1 21.3077V17.9231M12 11.1538C12.8977 11.1538 13.7585 10.7973 14.3933 10.1625C15.028 9.52778 15.3846 8.66689 15.3846 7.76923C15.3846 6.87157 15.028 6.01068 14.3933 5.37595C13.7585 4.74121 12.8977 4.38462 12 4.38462C11.1023 4.38462 10.2415 4.74121 9.60672 5.37595C8.97198 6.01068 8.61538 6.87157 8.61538 7.76923C8.61538 8.66689 8.97198 9.52778 9.60672 10.1625C10.2415 10.7973 11.1023 11.1538 12 11.1538ZM18.4358 18.7692C17.9984 17.4061 17.1395 16.2171 15.9829 15.3735C14.8262 14.5299 13.4316 14.0753 12 14.0753C10.5684 14.0753 9.17378 14.5299 8.01714 15.3735C6.86049 16.2171 6.00157 17.4061 5.56415 18.7692H18.4358Z"
              stroke="#8C8C8C"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>

          {t("pages.title.profile")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate("/upgrade");
            setAnchorEl(null);
          }}
          className="upgrade"
          sx={{ pointerEvents: "none", opacity: 0.5 }}
        >
          <svg
            width="26"
            height="26"
            viewBox="0 0 26 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.45454 13L17.3636 13M13 18.4545L18.4545 13L13 7.54545M0.999999 13C0.999998 19.6273 6.37273 25 13 25C19.6273 25 25 19.6273 25 13C25 6.37273 19.6273 1 13 0.999999C6.37273 0.999999 0.999999 6.37273 0.999999 13Z"
              stroke="#8C8C8C"
              strokeWidth="2"
            />
          </svg>
          {t("pages.title.upgrade")}
        </MenuItem>
        <MenuItem className="select-language">
          <LocalizationOptions setAnchorEl={setAnchorEl} />
        </MenuItem>
        <MenuItem className="logout" onClick={handleLogout}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21.486 17.7679H19.5643C19.4331 17.7679 19.3101 17.8253 19.2281 17.9264C19.0368 18.1588 18.8318 18.3829 18.6158 18.5961C17.7326 19.4802 16.6864 20.1848 15.5352 20.6709C14.3424 21.1746 13.0604 21.4331 11.7656 21.4308C10.4563 21.4308 9.18794 21.1738 7.99613 20.6709C6.84485 20.1848 5.79868 19.4802 4.91546 18.5961C4.03067 17.715 3.32515 16.6707 2.83799 15.5209C2.33229 14.3291 2.07808 13.0635 2.07808 11.7541C2.07808 10.4448 2.33503 9.17917 2.83799 7.98736C3.32456 6.83655 4.02434 5.80055 4.91546 4.91216C5.80659 4.02377 6.84259 3.32399 7.99613 2.83743C9.18794 2.33446 10.4563 2.07751 11.7656 2.07751C13.075 2.07751 14.3433 2.33173 15.5352 2.83743C16.6887 3.32399 17.7247 4.02377 18.6158 4.91216C18.8318 5.12811 19.034 5.35226 19.2281 5.58187C19.3101 5.68301 19.4359 5.74041 19.5643 5.74041H21.486C21.6582 5.74041 21.7648 5.54907 21.6691 5.40419C19.5725 2.14585 15.9042 -0.0108927 11.7356 4.13817e-05C5.18608 0.0164424 -0.0649962 5.33312 0.000608082 11.8744C0.0662123 18.3118 5.30909 23.5082 11.7656 23.5082C15.9233 23.5082 19.5753 21.3542 21.6691 18.1041C21.7621 17.9592 21.6582 17.7679 21.486 17.7679ZM23.9161 11.5819L20.0372 8.5204C19.8924 8.40559 19.6819 8.50946 19.6819 8.69261V10.7701H11.0987C10.9784 10.7701 10.88 10.8685 10.88 10.9888V12.5195C10.88 12.6398 10.9784 12.7382 11.0987 12.7382H19.6819V14.8157C19.6819 14.9988 19.8951 15.1027 20.0372 14.9879L23.9161 11.9263C23.9422 11.9059 23.9634 11.8798 23.9779 11.8499C23.9924 11.8201 24 11.7873 24 11.7541C24 11.7209 23.9924 11.6882 23.9779 11.6584C23.9634 11.6285 23.9422 11.6024 23.9161 11.5819Z"
              fill="#2c73ff"
            />
          </svg>
          Logout
        </MenuItem>
      </Menu>
      <div className="sidebar-link">
        <ul className={`sideNav  `}>
          <li>
            <Link
              className={`newLink ${currentRoute === "/software-dashboard" || currentRoute === "/"
                  ? "onActiveLink"
                  : ""
                }`}
              to={"/software-dashboard"}
            >
              <Tooltip
                title={!isOpen ? t("sidebar.menuItem.dashboard") : ""}
                placement="right"
                arrow
              >
                <svg
                  className="onStatic"
                  width="26"
                  height="26"
                  viewBox="0 0 26 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 2.5C1 2.10218 1.15804 1.72064 1.43934 1.43934C1.72064 1.15804 2.10218 1 2.5 1H8.5C8.89782 1 9.27936 1.15804 9.56066 1.43934C9.84196 1.72064 10 2.10218 10 2.5V10C10 10.3978 9.84196 10.7794 9.56066 11.0607C9.27936 11.342 8.89782 11.5 8.5 11.5H2.5C2.10218 11.5 1.72064 11.342 1.43934 11.0607C1.15804 10.7794 1 10.3978 1 10V2.5ZM16 2.5C16 2.10218 16.158 1.72064 16.4393 1.43934C16.7206 1.15804 17.1022 1 17.5 1H23.5C23.8978 1 24.2794 1.15804 24.5607 1.43934C24.842 1.72064 25 2.10218 25 2.5V5.5C25 5.89782 24.842 6.27936 24.5607 6.56066C24.2794 6.84196 23.8978 7 23.5 7H17.5C17.1022 7 16.7206 6.84196 16.4393 6.56066C16.158 6.27936 16 5.89782 16 5.5V2.5ZM1 19C1 18.6022 1.15804 18.2206 1.43934 17.9393C1.72064 17.658 2.10218 17.5 2.5 17.5H8.5C8.89782 17.5 9.27936 17.658 9.56066 17.9393C9.84196 18.2206 10 18.6022 10 19V23.5C10 23.8978 9.84196 24.2794 9.56066 24.5607C9.27936 24.842 8.89782 25 8.5 25H2.5C2.10218 25 1.72064 24.842 1.43934 24.5607C1.15804 24.2794 1 23.8978 1 23.5V19ZM16 14.5C16 14.1022 16.158 13.7206 16.4393 13.4393C16.7206 13.158 17.1022 13 17.5 13H23.5C23.8978 13 24.2794 13.158 24.5607 13.4393C24.842 13.7206 25 14.1022 25 14.5V23.5C25 23.8978 24.842 24.2794 24.5607 24.5607C24.2794 24.842 23.8978 25 23.5 25H17.5C17.1022 25 16.7206 24.842 16.4393 24.5607C16.158 24.2794 16 23.8978 16 23.5V14.5Z"
                    stroke="#8C8C8C"
                    strokeWidth="2"
                  />
                </svg>
                <svg
                  className="onHover"
                  width="26"
                  height="26"
                  viewBox="0 0 26 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 2.5C1 2.10218 1.15804 1.72064 1.43934 1.43934C1.72064 1.15804 2.10218 1 2.5 1H8.5C8.89782 1 9.27936 1.15804 9.56066 1.43934C9.84196 1.72064 10 2.10218 10 2.5V10C10 10.3978 9.84196 10.7794 9.56066 11.0607C9.27936 11.342 8.89782 11.5 8.5 11.5H2.5C2.10218 11.5 1.72064 11.342 1.43934 11.0607C1.15804 10.7794 1 10.3978 1 10V2.5ZM16 2.5C16 2.10218 16.158 1.72064 16.4393 1.43934C16.7206 1.15804 17.1022 1 17.5 1H23.5C23.8978 1 24.2794 1.15804 24.5607 1.43934C24.842 1.72064 25 2.10218 25 2.5V5.5C25 5.89782 24.842 6.27936 24.5607 6.56066C24.2794 6.84196 23.8978 7 23.5 7H17.5C17.1022 7 16.7206 6.84196 16.4393 6.56066C16.158 6.27936 16 5.89782 16 5.5V2.5ZM1 19C1 18.6022 1.15804 18.2206 1.43934 17.9393C1.72064 17.658 2.10218 17.5 2.5 17.5H8.5C8.89782 17.5 9.27936 17.658 9.56066 17.9393C9.84196 18.2206 10 18.6022 10 19V23.5C10 23.8978 9.84196 24.2794 9.56066 24.5607C9.27936 24.842 8.89782 25 8.5 25H2.5C2.10218 25 1.72064 24.842 1.43934 24.5607C1.15804 24.2794 1 23.8978 1 23.5V19ZM16 14.5C16 14.1022 16.158 13.7206 16.4393 13.4393C16.7206 13.158 17.1022 13 17.5 13H23.5C23.8978 13 24.2794 13.158 24.5607 13.4393C24.842 13.7206 25 14.1022 25 14.5V23.5C25 23.8978 24.842 24.2794 24.5607 24.5607C24.2794 24.842 23.8978 25 23.5 25H17.5C17.1022 25 16.7206 24.842 16.4393 24.5607C16.158 24.2794 16 23.8978 16 23.5V14.5Z"
                    stroke="#2C73FF"
                    strokeWidth="2"
                  />
                </svg>
                <svg
                  className="onActive"
                  width="26"
                  height="26"
                  viewBox="0 0 26 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 2.5C1 2.10218 1.15804 1.72064 1.43934 1.43934C1.72064 1.15804 2.10218 1 2.5 1H8.5C8.89782 1 9.27936 1.15804 9.56066 1.43934C9.84196 1.72064 10 2.10218 10 2.5V10C10 10.3978 9.84196 10.7794 9.56066 11.0607C9.27936 11.342 8.89782 11.5 8.5 11.5H2.5C2.10218 11.5 1.72064 11.342 1.43934 11.0607C1.15804 10.7794 1 10.3978 1 10V2.5ZM16 2.5C16 2.10218 16.158 1.72064 16.4393 1.43934C16.7206 1.15804 17.1022 1 17.5 1H23.5C23.8978 1 24.2794 1.15804 24.5607 1.43934C24.842 1.72064 25 2.10218 25 2.5V5.5C25 5.89782 24.842 6.27936 24.5607 6.56066C24.2794 6.84196 23.8978 7 23.5 7H17.5C17.1022 7 16.7206 6.84196 16.4393 6.56066C16.158 6.27936 16 5.89782 16 5.5V2.5ZM1 19C1 18.6022 1.15804 18.2206 1.43934 17.9393C1.72064 17.658 2.10218 17.5 2.5 17.5H8.5C8.89782 17.5 9.27936 17.658 9.56066 17.9393C9.84196 18.2206 10 18.6022 10 19V23.5C10 23.8978 9.84196 24.2794 9.56066 24.5607C9.27936 24.842 8.89782 25 8.5 25H2.5C2.10218 25 1.72064 24.842 1.43934 24.5607C1.15804 24.2794 1 23.8978 1 23.5V19ZM16 14.5C16 14.1022 16.158 13.7206 16.4393 13.4393C16.7206 13.158 17.1022 13 17.5 13H23.5C23.8978 13 24.2794 13.158 24.5607 13.4393C24.842 13.7206 25 14.1022 25 14.5V23.5C25 23.8978 24.842 24.2794 24.5607 24.5607C24.2794 24.842 23.8978 25 23.5 25H17.5C17.1022 25 16.7206 24.842 16.4393 24.5607C16.158 24.2794 16 23.8978 16 23.5V14.5Z"
                    stroke="#fff"
                    strokeWidth="2"
                  />
                </svg>
                <span>{t("sidebar.menuItem.dashboard")}</span>
              </Tooltip>
            </Link>
          </li>
          <li>
            <Link
              className={`newLink ${currentRoute === "/prospection/facebook" ||
                  currentRoute === "/prospection/birthday" ||
                  currentRoute === "/prospection/connect-request" ||
                  currentRoute === "/prospection/instagram"
                  ? "onActiveLink"
                  : ""
                }`}
              to={"/prospection/facebook"}
            >
              <Tooltip
                title={!isOpen ? "Prospection" : ""}
                placement="right"
                arrow
              >
                <svg
                  className="onStatic"
                  width="26"
                  height="26"
                  viewBox="0 0 26 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.78464 14.8406L1.49182 22.1334"
                    stroke="#8C8C8C"
                    strokeOpacity="0.85"
                    strokeWidth="2"
                  />
                  <path
                    d="M11.1592 17.2153L3.86639 24.5082"
                    stroke="#8C8C8C"
                    strokeOpacity="0.85"
                    strokeWidth="2"
                  />
                  <path
                    d="M3.86651 24.5083C3.5516 24.8232 3.1245 25.0001 2.67916 25.0001C2.23382 25.0001 1.80672 24.8232 1.49182 24.5083"
                    stroke="#8C8C8C"
                    strokeOpacity="0.85"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M1.49181 22.1335C1.17691 22.4484 1 22.8755 1 23.3209C1 23.7662 1.17691 24.1933 1.49181 24.5082"
                    stroke="#8C8C8C"
                    strokeOpacity="0.85"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M24.9999 8.5562C24.9999 9.5485 24.8045 10.5311 24.4247 11.4478C24.045 12.3646 23.4884 13.1976 22.7868 13.8992C22.0851 14.6009 21.2521 15.1575 20.3353 15.5372C19.4186 15.917 18.436 16.1124 17.4437 16.1124C16.4514 16.1124 15.4688 15.917 14.5521 15.5372C13.6353 15.1575 12.8023 14.6009 12.1007 13.8992C11.399 13.1976 10.8424 12.3646 10.4627 11.4478C10.083 10.5311 9.88751 9.5485 9.88751 8.5562C9.88751 6.55217 10.6836 4.63022 12.1007 3.21316C13.5177 1.7961 15.4397 1 17.4437 1C18.436 1 19.4186 1.19545 20.3353 1.57518C21.2521 1.95492 22.0851 2.5115 22.7868 3.21316C23.4884 3.91482 24.045 4.74781 24.4247 5.66457C24.8045 6.58133 24.9999 7.56391 24.9999 8.5562Z"
                    stroke="#8C8C8C"
                    strokeOpacity="0.85"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8.78455 14.8406L11.1592 17.2153"
                    stroke="#8C8C8C"
                    strokeOpacity="0.85"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9.97205 16.0279L12.1008 13.8992"
                    stroke="#8C8C8C"
                    strokeOpacity="0.85"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M6.75763 16.8677L9.13231 19.2424"
                    stroke="#8C8C8C"
                    strokeOpacity="0.85"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M21.6416 8.55615C21.6416 9.6695 21.1993 10.7373 20.4121 11.5245C19.6248 12.3118 18.5571 12.754 17.4437 12.754"
                    stroke="#8C8C8C"
                    strokeOpacity="0.85"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <svg
                  className="onHover"
                  width="26"
                  height="26"
                  viewBox="0 0 26 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.78464 14.8406L1.49182 22.1334"
                    stroke="#2C73FF"
                    strokeWidth="2"
                  />
                  <path
                    d="M11.1592 17.2153L3.86639 24.5082"
                    stroke="#2C73FF"
                    strokeWidth="2"
                  />
                  <path
                    d="M3.86651 24.5083C3.5516 24.8232 3.1245 25.0001 2.67916 25.0001C2.23382 25.0001 1.80672 24.8232 1.49182 24.5083"
                    stroke="#2C73FF"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M1.49181 22.1335C1.17691 22.4484 1 22.8755 1 23.3209C1 23.7662 1.17691 24.1933 1.49181 24.5082"
                    stroke="#2C73FF"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M24.9999 8.5562C24.9999 9.5485 24.8045 10.5311 24.4247 11.4478C24.045 12.3646 23.4884 13.1976 22.7868 13.8992C22.0851 14.6009 21.2521 15.1575 20.3353 15.5372C19.4186 15.917 18.436 16.1124 17.4437 16.1124C16.4514 16.1124 15.4688 15.917 14.5521 15.5372C13.6353 15.1575 12.8023 14.6009 12.1007 13.8992C11.399 13.1976 10.8424 12.3646 10.4627 11.4478C10.083 10.5311 9.88751 9.5485 9.88751 8.5562C9.88751 6.55217 10.6836 4.63022 12.1007 3.21316C13.5177 1.7961 15.4397 1 17.4437 1C18.436 1 19.4186 1.19545 20.3353 1.57518C21.2521 1.95492 22.0851 2.5115 22.7868 3.21316C23.4884 3.91482 24.045 4.74781 24.4247 5.66457C24.8045 6.58133 24.9999 7.56391 24.9999 8.5562Z"
                    stroke="#2C73FF"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8.78455 14.8406L11.1592 17.2153"
                    stroke="#2C73FF"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9.97205 16.0279L12.1008 13.8992"
                    stroke="#2C73FF"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M6.75763 16.8677L9.13231 19.2424"
                    stroke="#2C73FF"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M21.6416 8.55615C21.6416 9.6695 21.1993 10.7373 20.4121 11.5245C19.6248 12.3118 18.5571 12.754 17.4437 12.754"
                    stroke="#2C73FF"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <svg
                  className="onActive"
                  width="26"
                  height="26"
                  viewBox="0 0 26 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.78464 14.8406L1.49182 22.1334"
                    stroke="white"
                    strokeOpacity="0.85"
                    strokeWidth="2"
                  />
                  <path
                    d="M11.1592 17.2153L3.86639 24.5082"
                    stroke="white"
                    strokeOpacity="0.85"
                    strokeWidth="2"
                  />
                  <path
                    d="M3.86651 24.5083C3.5516 24.8232 3.1245 25.0001 2.67916 25.0001C2.23382 25.0001 1.80672 24.8232 1.49182 24.5083"
                    stroke="white"
                    strokeOpacity="0.85"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M1.49181 22.1335C1.17691 22.4484 1 22.8755 1 23.3209C1 23.7662 1.17691 24.1933 1.49181 24.5082"
                    stroke="white"
                    strokeOpacity="0.85"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M24.9999 8.5562C24.9999 9.5485 24.8045 10.5311 24.4247 11.4478C24.045 12.3646 23.4884 13.1976 22.7868 13.8992C22.0851 14.6009 21.2521 15.1575 20.3353 15.5372C19.4186 15.917 18.436 16.1124 17.4437 16.1124C16.4514 16.1124 15.4688 15.917 14.5521 15.5372C13.6353 15.1575 12.8023 14.6009 12.1007 13.8992C11.399 13.1976 10.8424 12.3646 10.4627 11.4478C10.083 10.5311 9.88751 9.5485 9.88751 8.5562C9.88751 6.55217 10.6836 4.63022 12.1007 3.21316C13.5177 1.7961 15.4397 1 17.4437 1C18.436 1 19.4186 1.19545 20.3353 1.57518C21.2521 1.95492 22.0851 2.5115 22.7868 3.21316C23.4884 3.91482 24.045 4.74781 24.4247 5.66457C24.8045 6.58133 24.9999 7.56391 24.9999 8.5562Z"
                    stroke="white"
                    strokeOpacity="0.85"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8.78455 14.8406L11.1592 17.2153"
                    stroke="white"
                    strokeOpacity="0.85"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9.97205 16.0279L12.1008 13.8992"
                    stroke="white"
                    strokeOpacity="0.85"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M6.75763 16.8677L9.13231 19.2424"
                    stroke="white"
                    strokeOpacity="0.85"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M21.6416 8.55615C21.6416 9.6695 21.1993 10.7373 20.4121 11.5245C19.6248 12.3118 18.5571 12.754 17.4437 12.754"
                    stroke="white"
                    strokeOpacity="0.85"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                {/* 1234 */}
                <span>Prospecting</span>
              </Tooltip>
            </Link>
          </li>
          <li>
            <Link
              className={`newLink ${currentRoute === "/new-group" ? "onActiveLink" : ""
                }`}
              to={"/new-group"}
            >
              <Tooltip
                title={!isOpen ? t("sidebar.menuItem.facebook-crm") : ""}
                placement="right"
                arrow
              >
                <svg
                  className="onStatic"
                  width="16"
                  height="26"
                  viewBox="0 0 16 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.07542 6.8224V10.4464H1V14.92H5.07542V25H9.89854V14.92H14.0084L14.5003 10.4488H9.89854V6.8224C9.89854 6.1816 10.4175 5.8624 10.6364 5.6656C11.0225 5.344 12.8869 5.2936 12.8869 5.2936H14.7143V1.24C13.7628 1.10129 12.8033 1.02114 11.8416 1C4.93523 1 5.07542 6.8224 5.07542 6.8224Z"
                    stroke="#8C8C8C"
                    strokeWidth="1.75"
                    strokeMiterlimit="10"
                  />
                </svg>

                <svg
                  className="onHover"
                  width="16"
                  height="26"
                  viewBox="0 0 16 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.07542 6.8224V10.4464H1V14.92H5.07542V25H9.89854V14.92H14.0084L14.5003 10.4488H9.89854V6.8224C9.89854 6.1816 10.4175 5.8624 10.6364 5.6656C11.0225 5.344 12.8869 5.2936 12.8869 5.2936H14.7143V1.24C13.7628 1.10129 12.8033 1.02114 11.8416 1C4.93523 1 5.07542 6.8224 5.07542 6.8224Z"
                    stroke="#2C73FF"
                    strokeWidth="1.75"
                    strokeMiterlimit="10"
                  />
                </svg>

                <svg
                  className="onActive"
                  width="16"
                  height="26"
                  viewBox="0 0 16 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.07542 6.8224V10.4464H1V14.92H5.07542V25H9.89854V14.92H14.0084L14.5003 10.4488H9.89854V6.8224C9.89854 6.1816 10.4175 5.8624 10.6364 5.6656C11.0225 5.344 12.8869 5.2936 12.8869 5.2936H14.7143V1.24C13.7628 1.10129 12.8033 1.02114 11.8416 1C4.93523 1 5.07542 6.8224 5.07542 6.8224Z"
                    stroke="white"
                    strokeWidth="1.75"
                    strokeMiterlimit="10"
                  />
                </svg>

                <span>{t("sidebar.menuItem.facebook-crm")}</span>
              </Tooltip>
            </Link>
          </li>
          <li>
            <Link
              className={`newLink ${currentRoute === "/instagram-crm" ? "onActiveLink" : ""
                }`}
              to={"/instagram-crm"}
            >
              <Tooltip
                title={!isOpen ? t("sidebar.menuItem.instagram-crm") : ""}
                placement="right"
                arrow
              >
                <svg
                  className="onStatic"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 5.75806C8.61291 5.75806 5.80646 8.51612 5.80646 11.9516C5.80646 15.3871 8.56452 18.1452 12 18.1452C15.4355 18.1452 18.1936 15.3387 18.1936 11.9516C18.1936 8.56451 15.3871 5.75806 12 5.75806ZM12 15.9193C9.82259 15.9193 8.03226 14.129 8.03226 11.9516C8.03226 9.77419 9.82259 7.98386 12 7.98386C14.1774 7.98386 15.9677 9.77419 15.9677 11.9516C15.9677 14.129 14.1774 15.9193 12 15.9193Z"
                    fill="#8C8C8C"
                  />
                  <path
                    d="M18.4355 7.01617C19.2104 7.01617 19.8387 6.38792 19.8387 5.61294C19.8387 4.83796 19.2104 4.20972 18.4355 4.20972C17.6605 4.20972 17.0322 4.83796 17.0322 5.61294C17.0322 6.38792 17.6605 7.01617 18.4355 7.01617Z"
                    fill="#8C8C8C"
                  />
                  <path
                    d="M22.0645 1.98387C20.8065 0.677419 19.0161 0 16.9839 0H7.01613C2.80645 0 0 2.80645 0 7.01613V16.9355C0 19.0161 0.677419 20.8065 2.03226 22.1129C3.33871 23.371 5.08065 24 7.06452 24H16.9355C19.0161 24 20.7581 23.3226 22.0161 22.1129C23.3226 20.8548 24 19.0645 24 16.9839V7.01613C24 4.98387 23.3226 3.24194 22.0645 1.98387ZM21.871 16.9839C21.871 18.4839 21.3387 19.6935 20.4677 20.5161C19.5968 21.3387 18.3871 21.7742 16.9355 21.7742H7.06452C5.6129 21.7742 4.40323 21.3387 3.53226 20.5161C2.66129 19.6452 2.22581 18.4355 2.22581 16.9355V7.01613C2.22581 5.56452 2.66129 4.35484 3.53226 3.48387C4.35484 2.66129 5.6129 2.22581 7.06452 2.22581H17.0323C18.4839 2.22581 19.6935 2.66129 20.5645 3.53226C21.3871 4.40323 21.871 5.6129 21.871 7.01613V16.9839Z"
                    fill="#8C8C8C"
                  />
                </svg>

                <svg
                  className="onHover"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 5.75806C8.61291 5.75806 5.80646 8.51612 5.80646 11.9516C5.80646 15.3871 8.56452 18.1452 12 18.1452C15.4355 18.1452 18.1936 15.3387 18.1936 11.9516C18.1936 8.56451 15.3871 5.75806 12 5.75806ZM12 15.9193C9.82259 15.9193 8.03226 14.129 8.03226 11.9516C8.03226 9.77419 9.82259 7.98386 12 7.98386C14.1774 7.98386 15.9677 9.77419 15.9677 11.9516C15.9677 14.129 14.1774 15.9193 12 15.9193Z"
                    fill="#2C73FF"
                  />
                  <path
                    d="M18.4355 7.01617C19.2104 7.01617 19.8387 6.38792 19.8387 5.61294C19.8387 4.83796 19.2104 4.20972 18.4355 4.20972C17.6605 4.20972 17.0322 4.83796 17.0322 5.61294C17.0322 6.38792 17.6605 7.01617 18.4355 7.01617Z"
                    fill="#2C73FF"
                  />
                  <path
                    d="M22.0645 1.98387C20.8065 0.677419 19.0161 0 16.9839 0H7.01613C2.80645 0 0 2.80645 0 7.01613V16.9355C0 19.0161 0.677419 20.8065 2.03226 22.1129C3.33871 23.371 5.08065 24 7.06452 24H16.9355C19.0161 24 20.7581 23.3226 22.0161 22.1129C23.3226 20.8548 24 19.0645 24 16.9839V7.01613C24 4.98387 23.3226 3.24194 22.0645 1.98387ZM21.871 16.9839C21.871 18.4839 21.3387 19.6935 20.4677 20.5161C19.5968 21.3387 18.3871 21.7742 16.9355 21.7742H7.06452C5.6129 21.7742 4.40323 21.3387 3.53226 20.5161C2.66129 19.6452 2.22581 18.4355 2.22581 16.9355V7.01613C2.22581 5.56452 2.66129 4.35484 3.53226 3.48387C4.35484 2.66129 5.6129 2.22581 7.06452 2.22581H17.0323C18.4839 2.22581 19.6935 2.66129 20.5645 3.53226C21.3871 4.40323 21.871 5.6129 21.871 7.01613V16.9839Z"
                    fill="#2C73FF"
                  />
                </svg>

                <svg
                  className="onActive"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 5.75806C8.61291 5.75806 5.80646 8.51612 5.80646 11.9516C5.80646 15.3871 8.56452 18.1452 12 18.1452C15.4355 18.1452 18.1936 15.3387 18.1936 11.9516C18.1936 8.56451 15.3871 5.75806 12 5.75806ZM12 15.9193C9.82259 15.9193 8.03226 14.129 8.03226 11.9516C8.03226 9.77419 9.82259 7.98386 12 7.98386C14.1774 7.98386 15.9677 9.77419 15.9677 11.9516C15.9677 14.129 14.1774 15.9193 12 15.9193Z"
                    fill="white"
                  />
                  <path
                    d="M18.4355 7.01617C19.2104 7.01617 19.8387 6.38792 19.8387 5.61294C19.8387 4.83796 19.2104 4.20972 18.4355 4.20972C17.6605 4.20972 17.0322 4.83796 17.0322 5.61294C17.0322 6.38792 17.6605 7.01617 18.4355 7.01617Z"
                    fill="white"
                  />
                  <path
                    d="M22.0645 1.98387C20.8065 0.677419 19.0161 0 16.9839 0H7.01613C2.80645 0 0 2.80645 0 7.01613V16.9355C0 19.0161 0.677419 20.8065 2.03226 22.1129C3.33871 23.371 5.08065 24 7.06452 24H16.9355C19.0161 24 20.7581 23.3226 22.0161 22.1129C23.3226 20.8548 24 19.0645 24 16.9839V7.01613C24 4.98387 23.3226 3.24194 22.0645 1.98387ZM21.871 16.9839C21.871 18.4839 21.3387 19.6935 20.4677 20.5161C19.5968 21.3387 18.3871 21.7742 16.9355 21.7742H7.06452C5.6129 21.7742 4.40323 21.3387 3.53226 20.5161C2.66129 19.6452 2.22581 18.4355 2.22581 16.9355V7.01613C2.22581 5.56452 2.66129 4.35484 3.53226 3.48387C4.35484 2.66129 5.6129 2.22581 7.06452 2.22581H17.0323C18.4839 2.22581 19.6935 2.66129 20.5645 3.53226C21.3871 4.40323 21.871 5.6129 21.871 7.01613V16.9839Z"
                    fill="white"
                  />
                </svg>

                <span>{t("sidebar.menuItem.instagram-crm")}</span>
              </Tooltip>
            </Link>
          </li>
          <li>
            <Link
              className={`newLink ${currentRoute === "/friends" ||
                  currentRoute === "/unfriended" ||
                  currentRoute === "/deactivated" ||
                  currentRoute === "/whitelist"
                  ? "onActiveLink"
                  : ""
                }`}
              to={"/friends"}
            >
              <Tooltip
                title={!isOpen ? t("sidebar.menuItem.fb_message_list") : ""}
                placement="right"
                arrow
              >
                <svg
                  className="onStatic"
                  width="32"
                  height="26"
                  viewBox="0 0 32 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M22.0405 19.8306C22.9366 19.8307 23.798 20.1865 24.4452 20.8239C25.0924 21.4613 25.4753 22.3311 25.5142 23.2519L25.5177 23.4071V24.1224C25.5178 24.4833 25.3853 24.8309 25.1467 25.0956C24.9081 25.3602 24.581 25.5223 24.2311 25.5494L24.1268 25.553H14.3909C14.04 25.5531 13.702 25.4168 13.4447 25.1714C13.1874 24.926 13.0298 24.5896 13.0035 24.2297L13 24.1224V23.4071C13.0001 22.4855 13.346 21.5994 13.9657 20.9338C14.5854 20.2681 15.431 19.8742 16.3262 19.8342L16.4771 19.8306H22.0405ZM22.0405 21.2612H16.4771C15.945 21.2612 15.4329 21.4703 15.0458 21.8458C14.6586 22.2213 14.4255 22.7348 14.3943 23.2812L14.3909 23.4071V24.1224H24.1268V23.4071C24.1269 22.8598 23.9235 22.3331 23.5585 21.9348C23.1934 21.5366 22.6942 21.2969 22.1629 21.2648L22.0405 21.2612ZM19.2588 11.2471C20.181 11.2471 21.0655 11.6239 21.7175 12.2946C22.3696 12.9653 22.736 13.875 22.736 14.8236C22.736 15.7721 22.3696 16.6818 21.7175 17.3525C21.0655 18.0232 20.181 18.4 19.2588 18.4C18.3366 18.4 17.4522 18.0232 16.8001 17.3525C16.148 16.6818 15.7817 15.7721 15.7817 14.8236C15.7817 13.875 16.148 12.9653 16.8001 12.2946C17.4522 11.6239 18.3366 11.2471 19.2588 11.2471ZM19.2588 12.6777C18.9849 12.6777 18.7136 12.7332 18.4605 12.841C18.2073 12.9488 17.9773 13.1069 17.7836 13.3062C17.5899 13.5054 17.4362 13.742 17.3314 14.0024C17.2265 14.2627 17.1726 14.5417 17.1726 14.8236C17.1726 15.1054 17.2265 15.3844 17.3314 15.6447C17.4362 15.9051 17.5899 16.1417 17.7836 16.3409C17.9773 16.5402 18.2073 16.6983 18.4605 16.8061C18.7136 16.9139 18.9849 16.9694 19.2588 16.9694C19.8122 16.9694 20.3428 16.7434 20.7341 16.3409C21.1253 15.9385 21.3451 15.3927 21.3451 14.8236C21.3451 14.2544 21.1253 13.7086 20.7341 13.3062C20.3428 12.9037 19.8122 12.6777 19.2588 12.6777Z"
                    fill="#8C8C8C"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M29.166 18.3647C29.678 18.3648 30.1703 18.5872 30.5401 18.9855C30.9099 19.3839 31.1288 19.9275 31.151 20.503L31.153 20.6V21.0471C31.153 21.2727 31.0773 21.4899 30.941 21.6553C30.8046 21.8207 30.6177 21.922 30.4178 21.939L30.3582 21.9412H24.7948C24.5943 21.9413 24.4011 21.8561 24.2541 21.7027C24.1071 21.5493 24.017 21.3391 24.002 21.1141L24 21.0471V20.6C24 20.024 24.1977 19.4702 24.5518 19.0542C24.906 18.6381 25.3892 18.392 25.9007 18.367L25.9869 18.3647H29.166ZM29.166 19.2588H25.9869C25.6828 19.2588 25.3903 19.3895 25.169 19.6242C24.9478 19.8589 24.8146 20.1798 24.7968 20.5213L24.7948 20.6V21.0471H30.3582V20.6C30.3582 20.2579 30.242 19.9288 30.0334 19.6799C29.8248 19.431 29.5395 19.2811 29.236 19.2611L29.166 19.2588ZM27.5765 13C28.1034 13 28.6088 13.2355 28.9815 13.6547C29.3541 14.0739 29.5634 14.6425 29.5634 15.2353C29.5634 15.8281 29.3541 16.3967 28.9815 16.8159C28.6088 17.2351 28.1034 17.4706 27.5765 17.4706C27.0495 17.4706 26.5441 17.2351 26.1715 16.8159C25.7989 16.3967 25.5895 15.8281 25.5895 15.2353C25.5895 14.6425 25.7989 14.0739 26.1715 13.6547C26.5441 13.2355 27.0495 13 27.5765 13ZM27.5765 13.8941C27.4199 13.8941 27.2649 13.9288 27.1203 13.9962C26.9756 14.0636 26.8442 14.1624 26.7335 14.2869C26.6228 14.4115 26.535 14.5593 26.4751 14.7221C26.4152 14.8848 26.3843 15.0592 26.3843 15.2353C26.3843 15.4114 26.4152 15.5858 26.4751 15.7485C26.535 15.9113 26.6228 16.0591 26.7335 16.1837C26.8442 16.3082 26.9756 16.407 27.1203 16.4744C27.2649 16.5418 27.4199 16.5765 27.5765 16.5765C27.8927 16.5765 28.1959 16.4352 28.4195 16.1837C28.643 15.9321 28.7686 15.591 28.7686 15.2353C28.7686 14.8796 28.643 14.5385 28.4195 14.2869C28.1959 14.0354 27.8927 13.8941 27.5765 13.8941Z"
                    fill="#8C8C8C"
                    stroke="#8C8C8C"
                    strokeWidth="0.25"
                  />
                  <path
                    d="M5.07542 6.8224V10.4464H1V14.92H5.07542V25H9.89854V14.92H14.0084L14.5003 10.4488H9.89854V6.8224C9.89854 6.1816 10.4175 5.8624 10.6364 5.6656C11.0225 5.344 12.8869 5.2936 12.8869 5.2936H14.7143V1.24C13.7628 1.10129 12.8033 1.02114 11.8416 1C4.93523 1 5.07542 6.8224 5.07542 6.8224Z"
                    stroke="#8C8C8C"
                    strokeWidth="1.75"
                    strokeMiterlimit="10"
                  />
                </svg>
                <svg
                  className="onHover"
                  width="32"
                  height="26"
                  viewBox="0 0 32 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M22.0405 19.8306C22.9366 19.8307 23.798 20.1865 24.4452 20.8239C25.0924 21.4613 25.4753 22.3311 25.5142 23.2519L25.5177 23.4071V24.1224C25.5178 24.4833 25.3853 24.8309 25.1467 25.0956C24.9081 25.3602 24.581 25.5223 24.2311 25.5494L24.1268 25.553H14.3909C14.04 25.5531 13.702 25.4168 13.4447 25.1714C13.1874 24.926 13.0298 24.5896 13.0035 24.2297L13 24.1224V23.4071C13.0001 22.4855 13.346 21.5994 13.9657 20.9338C14.5854 20.2681 15.431 19.8742 16.3262 19.8342L16.4771 19.8306H22.0405ZM22.0405 21.2612H16.4771C15.945 21.2612 15.4329 21.4703 15.0458 21.8458C14.6586 22.2213 14.4255 22.7348 14.3943 23.2812L14.3909 23.4071V24.1224H24.1268V23.4071C24.1269 22.8598 23.9235 22.3331 23.5585 21.9348C23.1934 21.5366 22.6942 21.2969 22.1629 21.2648L22.0405 21.2612ZM19.2588 11.2471C20.181 11.2471 21.0655 11.6239 21.7175 12.2946C22.3696 12.9653 22.736 13.875 22.736 14.8236C22.736 15.7721 22.3696 16.6818 21.7175 17.3525C21.0655 18.0232 20.181 18.4 19.2588 18.4C18.3366 18.4 17.4522 18.0232 16.8001 17.3525C16.148 16.6818 15.7817 15.7721 15.7817 14.8236C15.7817 13.875 16.148 12.9653 16.8001 12.2946C17.4522 11.6239 18.3366 11.2471 19.2588 11.2471ZM19.2588 12.6777C18.9849 12.6777 18.7136 12.7332 18.4605 12.841C18.2073 12.9488 17.9773 13.1069 17.7836 13.3062C17.5899 13.5054 17.4362 13.742 17.3314 14.0024C17.2265 14.2627 17.1726 14.5417 17.1726 14.8236C17.1726 15.1054 17.2265 15.3844 17.3314 15.6447C17.4362 15.9051 17.5899 16.1417 17.7836 16.3409C17.9773 16.5402 18.2073 16.6983 18.4605 16.8061C18.7136 16.9139 18.9849 16.9694 19.2588 16.9694C19.8122 16.9694 20.3428 16.7434 20.7341 16.3409C21.1253 15.9385 21.3451 15.3927 21.3451 14.8236C21.3451 14.2544 21.1253 13.7086 20.7341 13.3062C20.3428 12.9037 19.8122 12.6777 19.2588 12.6777Z"
                    fill="#2C73FF"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M29.166 18.3647C29.678 18.3648 30.1703 18.5872 30.5401 18.9855C30.9099 19.3839 31.1288 19.9275 31.151 20.503L31.153 20.6V21.0471C31.153 21.2727 31.0773 21.4899 30.941 21.6553C30.8046 21.8207 30.6177 21.922 30.4178 21.939L30.3582 21.9412H24.7948C24.5943 21.9413 24.4011 21.8561 24.2541 21.7027C24.1071 21.5493 24.017 21.3391 24.002 21.1141L24 21.0471V20.6C24 20.024 24.1977 19.4702 24.5518 19.0542C24.906 18.6381 25.3892 18.392 25.9007 18.367L25.9869 18.3647H29.166ZM29.166 19.2588H25.9869C25.6828 19.2588 25.3903 19.3895 25.169 19.6242C24.9478 19.8589 24.8146 20.1798 24.7968 20.5213L24.7948 20.6V21.0471H30.3582V20.6C30.3582 20.2579 30.242 19.9288 30.0334 19.6799C29.8248 19.431 29.5395 19.2811 29.236 19.2611L29.166 19.2588ZM27.5765 13C28.1034 13 28.6088 13.2355 28.9815 13.6547C29.3541 14.0739 29.5634 14.6425 29.5634 15.2353C29.5634 15.8281 29.3541 16.3967 28.9815 16.8159C28.6088 17.2351 28.1034 17.4706 27.5765 17.4706C27.0495 17.4706 26.5441 17.2351 26.1715 16.8159C25.7989 16.3967 25.5895 15.8281 25.5895 15.2353C25.5895 14.6425 25.7989 14.0739 26.1715 13.6547C26.5441 13.2355 27.0495 13 27.5765 13ZM27.5765 13.8941C27.4199 13.8941 27.2649 13.9288 27.1203 13.9962C26.9756 14.0636 26.8442 14.1624 26.7335 14.2869C26.6228 14.4115 26.535 14.5593 26.4751 14.7221C26.4152 14.8848 26.3843 15.0592 26.3843 15.2353C26.3843 15.4114 26.4152 15.5858 26.4751 15.7485C26.535 15.9113 26.6228 16.0591 26.7335 16.1837C26.8442 16.3082 26.9756 16.407 27.1203 16.4744C27.2649 16.5418 27.4199 16.5765 27.5765 16.5765C27.8927 16.5765 28.1959 16.4352 28.4195 16.1837C28.643 15.9321 28.7686 15.591 28.7686 15.2353C28.7686 14.8796 28.643 14.5385 28.4195 14.2869C28.1959 14.0354 27.8927 13.8941 27.5765 13.8941Z"
                    fill="#2C73FF"
                    stroke="#2C73FF"
                    strokeWidth="0.25"
                  />
                  <path
                    d="M5.07542 6.8224V10.4464H1V14.92H5.07542V25H9.89854V14.92H14.0084L14.5003 10.4488H9.89854V6.8224C9.89854 6.1816 10.4175 5.8624 10.6364 5.6656C11.0225 5.344 12.8869 5.2936 12.8869 5.2936H14.7143V1.24C13.7628 1.10129 12.8033 1.02114 11.8416 1C4.93523 1 5.07542 6.8224 5.07542 6.8224Z"
                    stroke="#2C73FF"
                    strokeWidth="1.75"
                    strokeMiterlimit="10"
                  />
                </svg>
                <svg
                  className="onActive"
                  width="32"
                  height="26"
                  viewBox="0 0 32 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M22.0405 19.8306C22.9366 19.8307 23.798 20.1865 24.4452 20.8239C25.0924 21.4613 25.4753 22.3311 25.5142 23.2519L25.5177 23.4071V24.1224C25.5178 24.4833 25.3853 24.8309 25.1467 25.0956C24.9081 25.3602 24.581 25.5223 24.2311 25.5494L24.1268 25.553H14.3909C14.04 25.5531 13.702 25.4168 13.4447 25.1714C13.1874 24.926 13.0298 24.5896 13.0035 24.2297L13 24.1224V23.4071C13.0001 22.4855 13.346 21.5994 13.9657 20.9338C14.5854 20.2681 15.431 19.8742 16.3262 19.8342L16.4771 19.8306H22.0405ZM22.0405 21.2612H16.4771C15.945 21.2612 15.4329 21.4703 15.0458 21.8458C14.6586 22.2213 14.4255 22.7348 14.3943 23.2812L14.3909 23.4071V24.1224H24.1268V23.4071C24.1269 22.8598 23.9235 22.3331 23.5585 21.9348C23.1934 21.5366 22.6942 21.2969 22.1629 21.2648L22.0405 21.2612ZM19.2588 11.2471C20.181 11.2471 21.0655 11.6239 21.7175 12.2946C22.3696 12.9653 22.736 13.875 22.736 14.8236C22.736 15.7721 22.3696 16.6818 21.7175 17.3525C21.0655 18.0232 20.181 18.4 19.2588 18.4C18.3366 18.4 17.4522 18.0232 16.8001 17.3525C16.148 16.6818 15.7817 15.7721 15.7817 14.8236C15.7817 13.875 16.148 12.9653 16.8001 12.2946C17.4522 11.6239 18.3366 11.2471 19.2588 11.2471ZM19.2588 12.6777C18.9849 12.6777 18.7136 12.7332 18.4605 12.841C18.2073 12.9488 17.9773 13.1069 17.7836 13.3062C17.5899 13.5054 17.4362 13.742 17.3314 14.0024C17.2265 14.2627 17.1726 14.5417 17.1726 14.8236C17.1726 15.1054 17.2265 15.3844 17.3314 15.6447C17.4362 15.9051 17.5899 16.1417 17.7836 16.3409C17.9773 16.5402 18.2073 16.6983 18.4605 16.8061C18.7136 16.9139 18.9849 16.9694 19.2588 16.9694C19.8122 16.9694 20.3428 16.7434 20.7341 16.3409C21.1253 15.9385 21.3451 15.3927 21.3451 14.8236C21.3451 14.2544 21.1253 13.7086 20.7341 13.3062C20.3428 12.9037 19.8122 12.6777 19.2588 12.6777Z"
                    fill="#fff"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M29.166 18.3647C29.678 18.3648 30.1703 18.5872 30.5401 18.9855C30.9099 19.3839 31.1288 19.9275 31.151 20.503L31.153 20.6V21.0471C31.153 21.2727 31.0773 21.4899 30.941 21.6553C30.8046 21.8207 30.6177 21.922 30.4178 21.939L30.3582 21.9412H24.7948C24.5943 21.9413 24.4011 21.8561 24.2541 21.7027C24.1071 21.5493 24.017 21.3391 24.002 21.1141L24 21.0471V20.6C24 20.024 24.1977 19.4702 24.5518 19.0542C24.906 18.6381 25.3892 18.392 25.9007 18.367L25.9869 18.3647H29.166ZM29.166 19.2588H25.9869C25.6828 19.2588 25.3903 19.3895 25.169 19.6242C24.9478 19.8589 24.8146 20.1798 24.7968 20.5213L24.7948 20.6V21.0471H30.3582V20.6C30.3582 20.2579 30.242 19.9288 30.0334 19.6799C29.8248 19.431 29.5395 19.2811 29.236 19.2611L29.166 19.2588ZM27.5765 13C28.1034 13 28.6088 13.2355 28.9815 13.6547C29.3541 14.0739 29.5634 14.6425 29.5634 15.2353C29.5634 15.8281 29.3541 16.3967 28.9815 16.8159C28.6088 17.2351 28.1034 17.4706 27.5765 17.4706C27.0495 17.4706 26.5441 17.2351 26.1715 16.8159C25.7989 16.3967 25.5895 15.8281 25.5895 15.2353C25.5895 14.6425 25.7989 14.0739 26.1715 13.6547C26.5441 13.2355 27.0495 13 27.5765 13ZM27.5765 13.8941C27.4199 13.8941 27.2649 13.9288 27.1203 13.9962C26.9756 14.0636 26.8442 14.1624 26.7335 14.2869C26.6228 14.4115 26.535 14.5593 26.4751 14.7221C26.4152 14.8848 26.3843 15.0592 26.3843 15.2353C26.3843 15.4114 26.4152 15.5858 26.4751 15.7485C26.535 15.9113 26.6228 16.0591 26.7335 16.1837C26.8442 16.3082 26.9756 16.407 27.1203 16.4744C27.2649 16.5418 27.4199 16.5765 27.5765 16.5765C27.8927 16.5765 28.1959 16.4352 28.4195 16.1837C28.643 15.9321 28.7686 15.591 28.7686 15.2353C28.7686 14.8796 28.643 14.5385 28.4195 14.2869C28.1959 14.0354 27.8927 13.8941 27.5765 13.8941Z"
                    fill="#fff"
                    stroke="#fff"
                    strokeWidth="0.25"
                  />
                  <path
                    d="M5.07542 6.8224V10.4464H1V14.92H5.07542V25H9.89854V14.92H14.0084L14.5003 10.4488H9.89854V6.8224C9.89854 6.1816 10.4175 5.8624 10.6364 5.6656C11.0225 5.344 12.8869 5.2936 12.8869 5.2936H14.7143V1.24C13.7628 1.10129 12.8033 1.02114 11.8416 1C4.93523 1 5.07542 6.8224 5.07542 6.8224Z"
                    stroke="#fff"
                    strokeWidth="1.75"
                    strokeMiterlimit="10"
                  />
                </svg>
                {/* 1234 */}
                <span>{t("sidebar.menuItem.fb_message_list")}</span>
              </Tooltip>
            </Link>
          </li>
          <li>
            <Link
              className={`newLink ${currentRoute === "/comment-ai" ? "onActiveLink" : ""
                }`}
              to={"/comment-ai"}
            >
              <Tooltip
                title={!isOpen ? t("sidebar.menuItem.commentai") : ""}
                placement="right"
                arrow
              >
                <svg
                  className="onStatic"
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.8 14.5533H19.2V12.1533H4.8V14.5533ZM4.8 10.9533H19.2V8.55332H4.8V10.9533ZM4.8 7.35332H19.2V4.95332H4.8V7.35332ZM24 24.1533L19.2 19.3533H2.4C1.74 19.3533 1.1752 19.1185 0.7056 18.6489C0.236 18.1793 0.0008 17.6141 0 16.9533V2.55332C0 1.89332 0.2352 1.32852 0.7056 0.85892C1.176 0.38932 1.7408 0.15412 2.4 0.15332H21.6C22.26 0.15332 22.8252 0.38852 23.2956 0.85892C23.766 1.32932 24.0008 1.89412 24 2.55332V24.1533ZM2.4 16.9533H20.22L21.6 18.3033V2.55332H2.4V16.9533Z"
                    fill="#8C8C8C"
                  />
                </svg>
                <svg
                  className="onHover"
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.8 14.5533H19.2V12.1533H4.8V14.5533ZM4.8 10.9533H19.2V8.55332H4.8V10.9533ZM4.8 7.35332H19.2V4.95332H4.8V7.35332ZM24 24.1533L19.2 19.3533H2.4C1.74 19.3533 1.1752 19.1185 0.7056 18.6489C0.236 18.1793 0.0008 17.6141 0 16.9533V2.55332C0 1.89332 0.2352 1.32852 0.7056 0.85892C1.176 0.38932 1.7408 0.15412 2.4 0.15332H21.6C22.26 0.15332 22.8252 0.38852 23.2956 0.85892C23.766 1.32932 24.0008 1.89412 24 2.55332V24.1533ZM2.4 16.9533H20.22L21.6 18.3033V2.55332H2.4V16.9533Z"
                    fill="#2C73FF"
                  />
                </svg>
                <svg
                  className="onActive"
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.8 14.5533H19.2V12.1533H4.8V14.5533ZM4.8 10.9533H19.2V8.55332H4.8V10.9533ZM4.8 7.35332H19.2V4.95332H4.8V7.35332ZM24 24.1533L19.2 19.3533H2.4C1.74 19.3533 1.1752 19.1185 0.7056 18.6489C0.236 18.1793 0.0008 17.6141 0 16.9533V2.55332C0 1.89332 0.2352 1.32852 0.7056 0.85892C1.176 0.38932 1.7408 0.15412 2.4 0.15332H21.6C22.26 0.15332 22.8252 0.38852 23.2956 0.85892C23.766 1.32932 24.0008 1.89412 24 2.55332V24.1533ZM2.4 16.9533H20.22L21.6 18.3033V2.55332H2.4V16.9533Z"
                    fill="#fff"
                  />
                </svg>
                {/* 1234 */}
                <span>{t("sidebar.menuItem.commentai")}</span>
              </Tooltip>
            </Link>
          </li>
          <li>
            <Link
              className={`newLink ${
                currentRoute === "/messages" ? "onActiveLink" : ""
              }`}
              to={"/messages"}
            >
              <Tooltip
                title={!isOpen ? t("sidebar.menuItem.library") : ""}
                placement="right"
                arrow
              >
                <svg
                  className="onStatic"
                  width="26"
                  height="26"
                  viewBox="0 0 26 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.33337 4.556C6.33337 3.61289 6.70802 2.70841 7.3749 2.04153C8.04178 1.37465 8.94626 1 9.88937 1H21.444C21.911 1 22.3734 1.09198 22.8049 1.27068C23.2363 1.44939 23.6283 1.71132 23.9585 2.04153C24.2887 2.37173 24.5507 2.76374 24.7294 3.19518C24.9081 3.62661 25 4.08902 25 4.556V16.1107C25 16.5776 24.9081 17.0401 24.7294 17.4715C24.5507 17.9029 24.2887 18.2949 23.9585 18.6251C23.6283 18.9553 23.2363 19.2173 22.8049 19.396C22.3734 19.5747 21.911 19.6667 21.444 19.6667H9.88937C8.94626 19.6667 8.04178 19.292 7.3749 18.6251C6.70802 17.9583 6.33337 17.0538 6.33337 16.1107V4.556Z"
                    stroke="#8C8C8C"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M2.34933 6.67992C1.94046 6.91301 1.60036 7.24992 1.36343 7.65659C1.1265 8.06326 1.00113 8.52527 1 8.99592V22.3293C1 23.7959 2.2 24.9959 3.66667 24.9959H17C18 24.9959 18.544 24.4826 19 23.6626M11.6667 6.33325H18.3333M11.6667 10.3333H19.6667M11.6667 14.3333H15.6667"
                    stroke="#8C8C8C"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <svg
                  className="onHover"
                  width="26"
                  height="26"
                  viewBox="0 0 26 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.33337 4.556C6.33337 3.61289 6.70802 2.70841 7.3749 2.04153C8.04178 1.37465 8.94626 1 9.88937 1H21.444C21.911 1 22.3734 1.09198 22.8049 1.27068C23.2363 1.44939 23.6283 1.71132 23.9585 2.04153C24.2887 2.37173 24.5507 2.76374 24.7294 3.19518C24.9081 3.62661 25 4.08902 25 4.556V16.1107C25 16.5776 24.9081 17.0401 24.7294 17.4715C24.5507 17.9029 24.2887 18.2949 23.9585 18.6251C23.6283 18.9553 23.2363 19.2173 22.8049 19.396C22.3734 19.5747 21.911 19.6667 21.444 19.6667H9.88937C8.94626 19.6667 8.04178 19.292 7.3749 18.6251C6.70802 17.9583 6.33337 17.0538 6.33337 16.1107V4.556Z"
                    stroke="#2C73FF"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M2.34933 6.67992C1.94046 6.91301 1.60036 7.24992 1.36343 7.65659C1.1265 8.06326 1.00113 8.52527 1 8.99592V22.3293C1 23.7959 2.2 24.9959 3.66667 24.9959H17C18 24.9959 18.544 24.4826 19 23.6626M11.6667 6.33325H18.3333M11.6667 10.3333H19.6667M11.6667 14.3333H15.6667"
                    stroke="#2C73FF"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <svg
                  className="onActive"
                  width="26"
                  height="26"
                  viewBox="0 0 26 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.33337 4.556C6.33337 3.61289 6.70802 2.70841 7.3749 2.04153C8.04178 1.37465 8.94626 1 9.88937 1H21.444C21.911 1 22.3734 1.09198 22.8049 1.27068C23.2363 1.44939 23.6283 1.71132 23.9585 2.04153C24.2887 2.37173 24.5507 2.76374 24.7294 3.19518C24.9081 3.62661 25 4.08902 25 4.556V16.1107C25 16.5776 24.9081 17.0401 24.7294 17.4715C24.5507 17.9029 24.2887 18.2949 23.9585 18.6251C23.6283 18.9553 23.2363 19.2173 22.8049 19.396C22.3734 19.5747 21.911 19.6667 21.444 19.6667H9.88937C8.94626 19.6667 8.04178 19.292 7.3749 18.6251C6.70802 17.9583 6.33337 17.0538 6.33337 16.1107V4.556Z"
                    stroke="#fff"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M2.34933 6.67992C1.94046 6.91301 1.60036 7.24992 1.36343 7.65659C1.1265 8.06326 1.00113 8.52527 1 8.99592V22.3293C1 23.7959 2.2 24.9959 3.66667 24.9959H17C18 24.9959 18.544 24.4826 19 23.6626M11.6667 6.33325H18.3333M11.6667 10.3333H19.6667M11.6667 14.3333H15.6667"
                    stroke="#fff"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span>{t("sidebar.menuItem.library")}</span>
              </Tooltip>
            </Link>
          </li>

          {!hideMenu && (
            <li>
              <Link
                className={`newLink ${ currentRoute === "/affiliate" ? "onActiveLink" : ""
                  }`}
                to={'/affiliate'}
              >
                <Tooltip
                  title={!isOpen ? t("pages.title.affiliate") : ""}
                  placement="right"
                  arrow
                >
                  {" "}
                  <svg
                    className="onStatic"
                    width="26"
                    height="27"
                    viewBox="0 0 26 27"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.85714 13.1506H14.7143M19 7.15064L14.7143 13.1421L19 19.1506M25 4.5775C25 5.48681 24.6388 6.35888 23.9958 7.00186C23.3528 7.64484 22.4807 8.00607 21.5714 8.00607C20.6621 8.00607 19.79 7.64484 19.1471 7.00186C18.5041 6.35888 18.1429 5.48681 18.1429 4.5775C18.1429 3.66818 18.5041 2.79611 19.1471 2.15313C19.79 1.51015 20.6621 1.14893 21.5714 1.14893C22.4807 1.14893 23.3528 1.51015 23.9958 2.15313C24.6388 2.79611 25 3.66818 25 4.5775ZM25 21.7084C25 22.6177 24.6388 23.4897 23.9958 24.1327C23.3528 24.7757 22.4807 25.1369 21.5714 25.1369C20.6621 25.1369 19.79 24.7757 19.1471 24.1327C18.5041 23.4897 18.1429 22.6177 18.1429 21.7084C18.1429 20.799 18.5041 19.927 19.1471 19.284C19.79 18.641 20.6621 18.2798 21.5714 18.2798C22.4807 18.2798 23.3528 18.641 23.9958 19.284C24.6388 19.927 25 20.799 25 21.7084ZM7.85714 13.1421C7.85714 14.0514 7.49592 14.9235 6.85294 15.5664C6.20996 16.2094 5.33789 16.5706 4.42857 16.5706C3.51926 16.5706 2.64719 16.2094 2.00421 15.5664C1.36122 14.9235 1 14.0514 1 13.1421C1 12.2328 1.36122 11.3607 2.00421 10.7177C2.64719 10.0747 3.51926 9.7135 4.42857 9.7135C5.33789 9.7135 6.20996 10.0747 6.85294 10.7177C7.49592 11.3607 7.85714 12.2328 7.85714 13.1421Z"
                      stroke="#8C8C8C"
                      strokeWidth="2"
                      strokeLinecap="square"
                    />
                  </svg>
                  <svg
                    className="onHover"
                    width="26"
                    height="27"
                    viewBox="0 0 26 27"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.85714 13.1506H14.7143M19 7.15064L14.7143 13.1421L19 19.1506M25 4.5775C25 5.48681 24.6388 6.35888 23.9958 7.00186C23.3528 7.64484 22.4807 8.00607 21.5714 8.00607C20.6621 8.00607 19.79 7.64484 19.1471 7.00186C18.5041 6.35888 18.1429 5.48681 18.1429 4.5775C18.1429 3.66818 18.5041 2.79611 19.1471 2.15313C19.79 1.51015 20.6621 1.14893 21.5714 1.14893C22.4807 1.14893 23.3528 1.51015 23.9958 2.15313C24.6388 2.79611 25 3.66818 25 4.5775ZM25 21.7084C25 22.6177 24.6388 23.4897 23.9958 24.1327C23.3528 24.7757 22.4807 25.1369 21.5714 25.1369C20.6621 25.1369 19.79 24.7757 19.1471 24.1327C18.5041 23.4897 18.1429 22.6177 18.1429 21.7084C18.1429 20.799 18.5041 19.927 19.1471 19.284C19.79 18.641 20.6621 18.2798 21.5714 18.2798C22.4807 18.2798 23.3528 18.641 23.9958 19.284C24.6388 19.927 25 20.799 25 21.7084ZM7.85714 13.1421C7.85714 14.0514 7.49592 14.9235 6.85294 15.5664C6.20996 16.2094 5.33789 16.5706 4.42857 16.5706C3.51926 16.5706 2.64719 16.2094 2.00421 15.5664C1.36122 14.9235 1 14.0514 1 13.1421C1 12.2328 1.36122 11.3607 2.00421 10.7177C2.64719 10.0747 3.51926 9.7135 4.42857 9.7135C5.33789 9.7135 6.20996 10.0747 6.85294 10.7177C7.49592 11.3607 7.85714 12.2328 7.85714 13.1421Z"
                      stroke="#2C73FF"
                      strokeWidth="2"
                      strokeLinecap="square"
                    />
                  </svg>
                  <svg
                    className="onActive"
                    width="26"
                    height="27"
                    viewBox="0 0 26 27"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.85714 13.1506H14.7143M19 7.15064L14.7143 13.1421L19 19.1506M25 4.5775C25 5.48681 24.6388 6.35888 23.9958 7.00186C23.3528 7.64484 22.4807 8.00607 21.5714 8.00607C20.6621 8.00607 19.79 7.64484 19.1471 7.00186C18.5041 6.35888 18.1429 5.48681 18.1429 4.5775C18.1429 3.66818 18.5041 2.79611 19.1471 2.15313C19.79 1.51015 20.6621 1.14893 21.5714 1.14893C22.4807 1.14893 23.3528 1.51015 23.9958 2.15313C24.6388 2.79611 25 3.66818 25 4.5775ZM25 21.7084C25 22.6177 24.6388 23.4897 23.9958 24.1327C23.3528 24.7757 22.4807 25.1369 21.5714 25.1369C20.6621 25.1369 19.79 24.7757 19.1471 24.1327C18.5041 23.4897 18.1429 22.6177 18.1429 21.7084C18.1429 20.799 18.5041 19.927 19.1471 19.284C19.79 18.641 20.6621 18.2798 21.5714 18.2798C22.4807 18.2798 23.3528 18.641 23.9958 19.284C24.6388 19.927 25 20.799 25 21.7084ZM7.85714 13.1421C7.85714 14.0514 7.49592 14.9235 6.85294 15.5664C6.20996 16.2094 5.33789 16.5706 4.42857 16.5706C3.51926 16.5706 2.64719 16.2094 2.00421 15.5664C1.36122 14.9235 1 14.0514 1 13.1421C1 12.2328 1.36122 11.3607 2.00421 10.7177C2.64719 10.0747 3.51926 9.7135 4.42857 9.7135C5.33789 9.7135 6.20996 10.0747 6.85294 10.7177C7.49592 11.3607 7.85714 12.2328 7.85714 13.1421Z"
                      stroke="#fff"
                      strokeWidth="2"
                      strokeLinecap="square"
                    />
                  </svg>
                  <span>{t("pages.title.affiliate")}</span>
                </Tooltip>
              </Link>
            </li>
          )}
          {!hideMenu && (
            <li>
              <Link
                className={`newLink ${ currentRoute === "/german-events" ? "onActiveLink" : ""
                  }`}
                to={'/german-events'}
              >
                <Tooltip
                  title={!isOpen ? t("sidebar.menuItem.german-events") : ""}
                  placement="right"
                  arrow
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="14" viewBox="0 0 5 3">
                    <rect id="black_stripe" width="5" height="3" y="0" x="0" fill="#000" />
                    <rect id="red_stripe" width="5" height="2" y="1" x="0" fill="#D00" />
                    <rect id="gold_stripe" width="5" height="1" y="2" x="0" fill="#FFCE00" />
                  </svg>

                  <span>{t("sidebar.menuItem.german-events")}</span>
                </Tooltip>
              </Link>
            </li>
          )}

          <li className="hidden">
            <Link
              className={`newLink ${currentRoute === "/upgrade-price-plan" ? "onActiveLink" : ""
                }`}
              // to={"/upgrade-price-plan"}
              style={{ pointerEvents: "none", opacity: 0.5 }}
            >
              <svg
                className="onStatic"
                width="26"
                height="27"
                viewBox="0 0 26 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.45454 13.1372L17.3636 13.1372M13 18.5918L18.4545 13.1372L13 7.68266M0.999999 13.1372C0.999998 19.7645 6.37273 25.1372 13 25.1372C19.6273 25.1372 25 19.7645 25 13.1372C25 6.50993 19.6273 1.13721 13 1.13721C6.37273 1.13721 0.999999 6.50993 0.999999 13.1372Z"
                  stroke="#8C8C8C"
                  strokeWidth="2"
                />
              </svg>
              <svg
                className="onHover"
                width="26"
                height="27"
                viewBox="0 0 26 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.45454 13.1372L17.3636 13.1372M13 18.5918L18.4545 13.1372L13 7.68266M0.999999 13.1372C0.999998 19.7645 6.37273 25.1372 13 25.1372C19.6273 25.1372 25 19.7645 25 13.1372C25 6.50993 19.6273 1.13721 13 1.13721C6.37273 1.13721 0.999999 6.50993 0.999999 13.1372Z"
                  stroke="#2C73FF"
                  strokeWidth="2"
                />
              </svg>
              <svg
                className="onActive"
                width="26"
                height="27"
                viewBox="0 0 26 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.45454 13.1372L17.3636 13.1372M13 18.5918L18.4545 13.1372L13 7.68266M0.999999 13.1372C0.999998 19.7645 6.37273 25.1372 13 25.1372C19.6273 25.1372 25 19.7645 25 13.1372C25 6.50993 19.6273 1.13721 13 1.13721C6.37273 1.13721 0.999999 6.50993 0.999999 13.1372Z"
                  stroke="#fff"
                  strokeWidth="2"
                />
              </svg>
              <span>{t("pages.title.upgrade")}</span>
            </Link>
          </li>
          <li className="hidden">
            <Link
              className={`newLink ${currentRoute === "/profile" ? "onActiveLink" : ""
                }`}
              to={"/profile"}
            >
              <svg
                className="onStatic"
                width="26"
                height="27"
                viewBox="0 0 26 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M25 19.5987V23.2911C25 23.7807 24.8055 24.2503 24.4593 24.5965C24.1131 24.9427 23.6435 25.1372 23.1538 25.1372H19.4615M19.4615 1.13721H23.1538C23.6435 1.13721 24.1131 1.33171 24.4593 1.67793C24.8055 2.02415 25 2.49373 25 2.98336V6.67567M1 6.67567V2.98336C1 2.49373 1.1945 2.02415 1.54073 1.67793C1.88695 1.33171 2.35652 1.13721 2.84615 1.13721H6.53846M6.53846 25.1372H2.84615C2.35652 25.1372 1.88695 24.9427 1.54073 24.5965C1.1945 24.2503 1 23.7807 1 23.2911V19.5987M13 12.2141C13.9793 12.2141 14.9184 11.8251 15.6109 11.1327C16.3033 10.4402 16.6923 9.50108 16.6923 8.52182C16.6923 7.54256 16.3033 6.60341 15.6109 5.91097C14.9184 5.21852 13.9793 4.82951 13 4.82951C12.0207 4.82951 11.0816 5.21852 10.3891 5.91097C9.6967 6.60341 9.30769 7.54256 9.30769 8.52182C9.30769 9.50108 9.6967 10.4402 10.3891 11.1327C11.0816 11.8251 12.0207 12.2141 13 12.2141ZM20.0209 20.5218C19.5437 19.0348 18.6067 17.7376 17.3449 16.8174C16.0831 15.8971 14.5617 15.4012 13 15.4012C11.4383 15.4012 9.91686 15.8971 8.65506 16.8174C7.39327 17.7376 6.45626 19.0348 5.97908 20.5218H20.0209Z"
                  stroke="#8C8C8C"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <svg
                className="onHover"
                width="26"
                height="27"
                viewBox="0 0 26 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M25 19.5987V23.2911C25 23.7807 24.8055 24.2503 24.4593 24.5965C24.1131 24.9427 23.6435 25.1372 23.1538 25.1372H19.4615M19.4615 1.13721H23.1538C23.6435 1.13721 24.1131 1.33171 24.4593 1.67793C24.8055 2.02415 25 2.49373 25 2.98336V6.67567M1 6.67567V2.98336C1 2.49373 1.1945 2.02415 1.54073 1.67793C1.88695 1.33171 2.35652 1.13721 2.84615 1.13721H6.53846M6.53846 25.1372H2.84615C2.35652 25.1372 1.88695 24.9427 1.54073 24.5965C1.1945 24.2503 1 23.7807 1 23.2911V19.5987M13 12.2141C13.9793 12.2141 14.9184 11.8251 15.6109 11.1327C16.3033 10.4402 16.6923 9.50108 16.6923 8.52182C16.6923 7.54256 16.3033 6.60341 15.6109 5.91097C14.9184 5.21852 13.9793 4.82951 13 4.82951C12.0207 4.82951 11.0816 5.21852 10.3891 5.91097C9.6967 6.60341 9.30769 7.54256 9.30769 8.52182C9.30769 9.50108 9.6967 10.4402 10.3891 11.1327C11.0816 11.8251 12.0207 12.2141 13 12.2141ZM20.0209 20.5218C19.5437 19.0348 18.6067 17.7376 17.3449 16.8174C16.0831 15.8971 14.5617 15.4012 13 15.4012C11.4383 15.4012 9.91686 15.8971 8.65506 16.8174C7.39327 17.7376 6.45626 19.0348 5.97908 20.5218H20.0209Z"
                  stroke="#2C73FF"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <svg
                className="onActive"
                width="26"
                height="27"
                viewBox="0 0 26 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M25 19.5987V23.2911C25 23.7807 24.8055 24.2503 24.4593 24.5965C24.1131 24.9427 23.6435 25.1372 23.1538 25.1372H19.4615M19.4615 1.13721H23.1538C23.6435 1.13721 24.1131 1.33171 24.4593 1.67793C24.8055 2.02415 25 2.49373 25 2.98336V6.67567M1 6.67567V2.98336C1 2.49373 1.1945 2.02415 1.54073 1.67793C1.88695 1.33171 2.35652 1.13721 2.84615 1.13721H6.53846M6.53846 25.1372H2.84615C2.35652 25.1372 1.88695 24.9427 1.54073 24.5965C1.1945 24.2503 1 23.7807 1 23.2911V19.5987M13 12.2141C13.9793 12.2141 14.9184 11.8251 15.6109 11.1327C16.3033 10.4402 16.6923 9.50108 16.6923 8.52182C16.6923 7.54256 16.3033 6.60341 15.6109 5.91097C14.9184 5.21852 13.9793 4.82951 13 4.82951C12.0207 4.82951 11.0816 5.21852 10.3891 5.91097C9.6967 6.60341 9.30769 7.54256 9.30769 8.52182C9.30769 9.50108 9.6967 10.4402 10.3891 11.1327C11.0816 11.8251 12.0207 12.2141 13 12.2141ZM20.0209 20.5218C19.5437 19.0348 18.6067 17.7376 17.3449 16.8174C16.0831 15.8971 14.5617 15.4012 13 15.4012C11.4383 15.4012 9.91686 15.8971 8.65506 16.8174C7.39327 17.7376 6.45626 19.0348 5.97908 20.5218H20.0209Z"
                  stroke="#fff"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span>{t("sidebar.menuItem.profile")}</span>
            </Link>
          </li>
          <li className="hidden">
            <Link
              className={`newLink ${currentRoute === "/training-videos" ? "onActiveLink" : ""
                }`}
              style={{ pointerEvents: "none", opacity: 0.5 }}

            // to={"/training-videos"}
            >
              <svg
                className="onStatic"
                width="26"
                height="19"
                viewBox="0 0 26 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 15.0435V3.95652C1 3.1724 1.28095 2.4204 1.78105 1.86595C2.28115 1.31149 2.95942 1 3.66667 1H15C15.7072 1 16.3855 1.31149 16.8856 1.86595C17.3857 2.4204 17.6667 3.1724 17.6667 3.95652V15.0435C17.6667 15.8276 17.3857 16.5796 16.8856 17.1341C16.3855 17.6885 15.7072 18 15 18H3.66667C2.95942 18 2.28115 17.6885 1.78105 17.1341C1.28095 16.5796 1 15.8276 1 15.0435ZM23.8907 2.09539L18.5573 7.36243C18.487 7.43164 18.4307 7.51654 18.3921 7.61157C18.3535 7.7066 18.3335 7.8096 18.3333 7.91383V10.5422C18.3335 10.6464 18.3535 10.7494 18.3921 10.8444C18.4307 10.9395 18.487 11.0244 18.5573 11.0936L23.8907 16.3606C23.9868 16.4552 24.1055 16.517 24.2325 16.5386C24.3595 16.5601 24.4895 16.5405 24.6067 16.4821C24.7239 16.4237 24.8234 16.3289 24.8931 16.2093C24.9629 16.0897 25 15.9502 25 15.8077V2.64826C25 2.50578 24.9629 2.36633 24.8931 2.2467C24.8234 2.12706 24.7239 2.03233 24.6067 1.97391C24.4895 1.9155 24.3595 1.89589 24.2325 1.91744C24.1055 1.93899 23.9868 2.00079 23.8907 2.09539Z"
                  stroke="#8C8C8C"
                  strokeWidth="1.75"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <svg
                className="onHover"
                width="26"
                height="19"
                viewBox="0 0 26 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 15.0435V3.95652C1 3.1724 1.28095 2.4204 1.78105 1.86595C2.28115 1.31149 2.95942 1 3.66667 1H15C15.7072 1 16.3855 1.31149 16.8856 1.86595C17.3857 2.4204 17.6667 3.1724 17.6667 3.95652V15.0435C17.6667 15.8276 17.3857 16.5796 16.8856 17.1341C16.3855 17.6885 15.7072 18 15 18H3.66667C2.95942 18 2.28115 17.6885 1.78105 17.1341C1.28095 16.5796 1 15.8276 1 15.0435ZM23.8907 2.09539L18.5573 7.36243C18.487 7.43164 18.4307 7.51654 18.3921 7.61157C18.3535 7.7066 18.3335 7.8096 18.3333 7.91383V10.5422C18.3335 10.6464 18.3535 10.7494 18.3921 10.8444C18.4307 10.9395 18.487 11.0244 18.5573 11.0936L23.8907 16.3606C23.9868 16.4552 24.1055 16.517 24.2325 16.5386C24.3595 16.5601 24.4895 16.5405 24.6067 16.4821C24.7239 16.4237 24.8234 16.3289 24.8931 16.2093C24.9629 16.0897 25 15.9502 25 15.8077V2.64826C25 2.50578 24.9629 2.36633 24.8931 2.2467C24.8234 2.12706 24.7239 2.03233 24.6067 1.97391C24.4895 1.9155 24.3595 1.89589 24.2325 1.91744C24.1055 1.93899 23.9868 2.00079 23.8907 2.09539Z"
                  stroke="#2C73FF"
                  strokeWidth="1.75"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <svg
                className="onActive"
                width="26"
                height="19"
                viewBox="0 0 26 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 15.0435V3.95652C1 3.1724 1.28095 2.4204 1.78105 1.86595C2.28115 1.31149 2.95942 1 3.66667 1H15C15.7072 1 16.3855 1.31149 16.8856 1.86595C17.3857 2.4204 17.6667 3.1724 17.6667 3.95652V15.0435C17.6667 15.8276 17.3857 16.5796 16.8856 17.1341C16.3855 17.6885 15.7072 18 15 18H3.66667C2.95942 18 2.28115 17.6885 1.78105 17.1341C1.28095 16.5796 1 15.8276 1 15.0435ZM23.8907 2.09539L18.5573 7.36243C18.487 7.43164 18.4307 7.51654 18.3921 7.61157C18.3535 7.7066 18.3335 7.8096 18.3333 7.91383V10.5422C18.3335 10.6464 18.3535 10.7494 18.3921 10.8444C18.4307 10.9395 18.487 11.0244 18.5573 11.0936L23.8907 16.3606C23.9868 16.4552 24.1055 16.517 24.2325 16.5386C24.3595 16.5601 24.4895 16.5405 24.6067 16.4821C24.7239 16.4237 24.8234 16.3289 24.8931 16.2093C24.9629 16.0897 25 15.9502 25 15.8077V2.64826C25 2.50578 24.9629 2.36633 24.8931 2.2467C24.8234 2.12706 24.7239 2.03233 24.6067 1.97391C24.4895 1.9155 24.3595 1.89589 24.2325 1.91744C24.1055 1.93899 23.9868 2.00079 23.8907 2.09539Z"
                  stroke="white"
                  strokeWidth="1.75"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <span>{t("sidebar.menuItem.training-videos")}</span>
            </Link>
          </li>
          <li className="hidden">
            <Link
              className={`newLink ${currentRoute === "/logout" ? "onActiveLink" : ""
                }`}
              to={"/logout"}
            >
              <svg
                className="onStatic"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21.486 17.9051H19.5643C19.4331 17.9051 19.3101 17.9625 19.2281 18.0636C19.0368 18.296 18.8318 18.5201 18.6158 18.7333C17.7326 19.6174 16.6864 20.322 15.5352 20.8081C14.3424 21.3118 13.0604 21.5703 11.7656 21.568C10.4563 21.568 9.18794 21.311 7.99613 20.8081C6.84485 20.322 5.79868 19.6174 4.91546 18.7333C4.03067 17.8522 3.32515 16.8079 2.83799 15.6581C2.33229 14.4663 2.07808 13.2007 2.07808 11.8913C2.07808 10.582 2.33503 9.31638 2.83799 8.12457C3.32456 6.97376 4.02434 5.93776 4.91546 5.04937C5.80659 4.16098 6.84259 3.4612 7.99613 2.97463C9.18794 2.47167 10.4563 2.21472 11.7656 2.21472C13.075 2.21472 14.3433 2.46893 15.5352 2.97463C16.6887 3.4612 17.7247 4.16098 18.6158 5.04937C18.8318 5.26532 19.034 5.48946 19.2281 5.71908C19.3101 5.82022 19.4359 5.87762 19.5643 5.87762H21.486C21.6582 5.87762 21.7648 5.68628 21.6691 5.5414C19.5725 2.28305 15.9042 0.126314 11.7356 0.137248C5.18608 0.153649 -0.0649962 5.47033 0.000608082 12.0116C0.0662123 18.449 5.30909 23.6454 11.7656 23.6454C15.9233 23.6454 19.5753 21.4914 21.6691 18.2413C21.7621 18.0964 21.6582 17.9051 21.486 17.9051ZM23.9161 11.7191L20.0372 8.6576C19.8924 8.5428 19.6819 8.64667 19.6819 8.82981V10.9073H11.0987C10.9784 10.9073 10.88 11.0057 10.88 11.126V12.6567C10.88 12.777 10.9784 12.8754 11.0987 12.8754H19.6819V14.9529C19.6819 15.136 19.8951 15.2399 20.0372 15.1251L23.9161 12.0636C23.9422 12.0431 23.9634 12.017 23.9779 11.9871C23.9924 11.9573 24 11.9245 24 11.8913C24 11.8582 23.9924 11.8254 23.9779 11.7956C23.9634 11.7657 23.9422 11.7396 23.9161 11.7191Z"
                  fill="#8C8C8C"
                />
              </svg>
              <svg
                className="onHover"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21.486 17.9051H19.5643C19.4331 17.9051 19.3101 17.9625 19.2281 18.0636C19.0368 18.296 18.8318 18.5201 18.6158 18.7333C17.7326 19.6174 16.6864 20.322 15.5352 20.8081C14.3424 21.3118 13.0604 21.5703 11.7656 21.568C10.4563 21.568 9.18794 21.311 7.99613 20.8081C6.84485 20.322 5.79868 19.6174 4.91546 18.7333C4.03067 17.8522 3.32515 16.8079 2.83799 15.6581C2.33229 14.4663 2.07808 13.2007 2.07808 11.8913C2.07808 10.582 2.33503 9.31638 2.83799 8.12457C3.32456 6.97376 4.02434 5.93776 4.91546 5.04937C5.80659 4.16098 6.84259 3.4612 7.99613 2.97463C9.18794 2.47167 10.4563 2.21472 11.7656 2.21472C13.075 2.21472 14.3433 2.46893 15.5352 2.97463C16.6887 3.4612 17.7247 4.16098 18.6158 5.04937C18.8318 5.26532 19.034 5.48946 19.2281 5.71908C19.3101 5.82022 19.4359 5.87762 19.5643 5.87762H21.486C21.6582 5.87762 21.7648 5.68628 21.6691 5.5414C19.5725 2.28305 15.9042 0.126314 11.7356 0.137248C5.18608 0.153649 -0.0649962 5.47033 0.000608082 12.0116C0.0662123 18.449 5.30909 23.6454 11.7656 23.6454C15.9233 23.6454 19.5753 21.4914 21.6691 18.2413C21.7621 18.0964 21.6582 17.9051 21.486 17.9051ZM23.9161 11.7191L20.0372 8.6576C19.8924 8.5428 19.6819 8.64667 19.6819 8.82981V10.9073H11.0987C10.9784 10.9073 10.88 11.0057 10.88 11.126V12.6567C10.88 12.777 10.9784 12.8754 11.0987 12.8754H19.6819V14.9529C19.6819 15.136 19.8951 15.2399 20.0372 15.1251L23.9161 12.0636C23.9422 12.0431 23.9634 12.017 23.9779 11.9871C23.9924 11.9573 24 11.9245 24 11.8913C24 11.8582 23.9924 11.8254 23.9779 11.7956C23.9634 11.7657 23.9422 11.7396 23.9161 11.7191Z"
                  fill="#2C73FF"
                />
              </svg>
              <svg
                className="onActive"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21.486 17.9051H19.5643C19.4331 17.9051 19.3101 17.9625 19.2281 18.0636C19.0368 18.296 18.8318 18.5201 18.6158 18.7333C17.7326 19.6174 16.6864 20.322 15.5352 20.8081C14.3424 21.3118 13.0604 21.5703 11.7656 21.568C10.4563 21.568 9.18794 21.311 7.99613 20.8081C6.84485 20.322 5.79868 19.6174 4.91546 18.7333C4.03067 17.8522 3.32515 16.8079 2.83799 15.6581C2.33229 14.4663 2.07808 13.2007 2.07808 11.8913C2.07808 10.582 2.33503 9.31638 2.83799 8.12457C3.32456 6.97376 4.02434 5.93776 4.91546 5.04937C5.80659 4.16098 6.84259 3.4612 7.99613 2.97463C9.18794 2.47167 10.4563 2.21472 11.7656 2.21472C13.075 2.21472 14.3433 2.46893 15.5352 2.97463C16.6887 3.4612 17.7247 4.16098 18.6158 5.04937C18.8318 5.26532 19.034 5.48946 19.2281 5.71908C19.3101 5.82022 19.4359 5.87762 19.5643 5.87762H21.486C21.6582 5.87762 21.7648 5.68628 21.6691 5.5414C19.5725 2.28305 15.9042 0.126314 11.7356 0.137248C5.18608 0.153649 -0.0649962 5.47033 0.000608082 12.0116C0.0662123 18.449 5.30909 23.6454 11.7656 23.6454C15.9233 23.6454 19.5753 21.4914 21.6691 18.2413C21.7621 18.0964 21.6582 17.9051 21.486 17.9051ZM23.9161 11.7191L20.0372 8.6576C19.8924 8.5428 19.6819 8.64667 19.6819 8.82981V10.9073H11.0987C10.9784 10.9073 10.88 11.0057 10.88 11.126V12.6567C10.88 12.777 10.9784 12.8754 11.0987 12.8754H19.6819V14.9529C19.6819 15.136 19.8951 15.2399 20.0372 15.1251L23.9161 12.0636C23.9422 12.0431 23.9634 12.017 23.9779 11.9871C23.9924 11.9573 24 11.9245 24 11.8913C24 11.8582 23.9924 11.8254 23.9779 11.7956C23.9634 11.7657 23.9422 11.7396 23.9161 11.7191Z"
                  fill="#fff"
                />
              </svg>
              <span>{t("sidebar.menuItem.logout")}</span>
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
};

export default CustomSidebar;
