import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
} from "@mui/material";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import React, { useContext } from "react";

export default function CustomerCard(props) {
  const { loginUserData } = useContext(CustomProvider);
	const color = {
		"In trail" : "#2c73ff",
		"Active": "#22C55E",
		"Paid": "#22C55E",
		"To Be Collected": "#FFC804",
		"Payment Due": "#FF7A00",
		"Cancelled": "#FF0000"
	};

  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  const formatDate2 = (timestamp) => {
    if (!timestamp || isNaN(timestamp)) {
      return "Invalid Date";
    }
  
    const date = new Date(timestamp * 1000); // Convert to milliseconds
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };
  

  const formatDate1 = (dateInput) => {
    let date;
    date = new Date(dateInput); // Handle ISO 8601 format
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };
  const user = props?.refUser
  return (
    <Card variant="outlined">
      <CardHeader
        avatar={
          <Avatar
            alt="Avatar Image"
            src={
              user?.pictureUrl && user?.picture ? `${user?.pictureUrl}${user?.picture}` : null
            }
            sx={{ width: 44, height: 44 }}
          >
            {user?.firstname
              ? user.firstname.charAt(0).toUpperCase() 
              : ''}
          </Avatar>
        }
        title={
          <Typography >
            {user?.firstname + " " + user?.lastname}
          </Typography>
        }
      />
      <Divider variant="middle" />
      <CardContent>
        <table>
          <tbody>
            {Number(user?.sponsorid) === loginUserData?.user_id ? <tr>
              <th
                style={{
                  textAlign: "left",
                }}
              >
                Email
              </th>
              <td
                style={{
                  textAlign: "left",
                }}
              >
                {user?.email}
              </td>
            </tr> : null}
            
            <tr>
              <th
                style={{
                  textAlign: "left",
                }}
              >
                Plan
              </th>
              <td
                style={{
                  textAlign: "left",
                }}
              >
                {user?.plan_pkg === "Unlimited_new" ? "Unlimited" : user?.plan_pkg}
              </td>
            </tr>
            <tr>
              <th
                style={{
                  textAlign: "left",
                }}
              >
                Price
              </th>
              <td
                style={{
                  textAlign: "left",
                  
                }}
              >
                {`${user?.plan_price} ${user?.currency}`}
              </td>
            </tr>
            <tr>
              <th
                style={{
                  textAlign: "left",
                }}
              >
                Joining Date
              </th>
              <td
                style={{
                  textAlign: "left",
                }}
              >
                {user?.createdat && formatDate1(user?.createdat)}
              </td>
            </tr>
            <tr>
              <th
                style={{
                  textAlign: "left",
                }}
              >
                Next Payment
              </th>
              <td
                style={{
                  textAlign: "left",
                }}
              >
                {user?.nextBillingAt
                  ? formatDate2(user?.nextBillingAt)
                  : "No Date Available"}
              </td>
            </tr>
            <tr>
              <th
                style={{
                  textAlign: "left",
                }}
              >
                Sponsored by{" "}
              </th>
              <td
                style={{
                  textAlign: "left",
                }}
              >
                {Number(user?.sponsorid) === loginUserData?.user_id ? 'You' : ''}
              </td>
            </tr>
            <tr>
              <th
                style={{
                  textAlign: "left",
                }}
              >
                Status
              </th>
              <td
                style={{
                  textAlign: "left",
                }}
              >
                {user?.trial_status === 'Active' ? (
											<span className="sep-gridview-tags"  style={{
												background: color['In trial'],
											}}>
												{
													'In trial'
												}
											</span>
										) : (
											<span  style={{
                      background: color[user?.subscription_status],
											}}>
												{
                        user?.subscription_status
												}
											</span>
										)}
              </td>
            </tr>

          </tbody>
        </table>
      </CardContent>
    </Card>
  );
}
