import React, { useContext, useState } from "react";
import AffiliateLayout from "./common/AffiliateLayout";
import CustomerCard from "./common/CustomerCard";
import CustomerTable from "./common/CustomerTable";
import { useNavigate } from "react-router-dom";
import { Button, CircularProgress, FormControl, MenuItem, Select } from "@mui/material";
import { BsFunnel, BsGrid, BsListUl } from "react-icons/bs";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";

export default function AllAffiliatedCustomers() {
	const [dataType, setDataType] = useState("grid");
  const { loginUserData, refUsers , loading } = useContext(CustomProvider);
  const navigate = useNavigate();
  if (loginUserData?.user_type === 'Normal') {
    navigate("/affiliate");
  }
  // const [loader, setLoader] = useState(true);
  
  // const [dashboardData, setdDashboardData] = useState(null);

  // const GetRefUserData = () => {
  //   setLoader(true);
  //   getreferralUsers(

  //     (response) => {
  //       setRefUsers(response?.data?.data);
  //       setLoader(false);
  //     },
  //     (error) => {
  //       setLoader(false);
  //       console.log(error?.response?.data);
  //     }
  //   );
  // };

  // const GetDashboardData = () => {
  //   setLoader(true);
  //   dashboarddataApi(
  //     (response) => {
  //       setdDashboardData(response?.data?.dashboardData);
  //       setLoader(false);
  //     },
  //     (error) => {
  //       setLoader(false);
  //       console.log(error?.response?.data);
  //     }
  //   );
  // };

  // useEffect(() => {
  //   // GetDashboardData();
  //   GetRefUserData()
  // }, [])


  const handleChange = (event) => {
    console.log(event.target.value);
  };
  return (
    <>
      <AffiliateLayout>
        <div
          className="sep-title-area">
          <div className="sep-title-area-left">
            <h2>All Customers</h2>
          </div>
          <div
            className="sep-title-area-right">
            <FormControl sx={{ width: "150px" }}>
              <legend className="sep-title-select">Sort By</legend>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value="name-asc"
                label="Sort By"
                onChange={handleChange}
              >
                <MenuItem value="name-asc">Name (A-Z)</MenuItem>
                <MenuItem value="name-desc">Name (Z-A)</MenuItem>
              </Select>
            </FormControl>
            <Button className="sep-filter-btn"
              variant="contained"
              color="primary"
            >
              <BsFunnel />
              <span>Filter</span>
            </Button>
            <Button className="sep-viewlist-btn"
            onClick={()=>setDataType(dataType === "grid"?"list":"grid")}
              variant="contained"
              color="success"
            >
              {dataType === "grid" ? (
                <>
                  <BsListUl />
                  <span>List View</span>
                </>
              ) : (
                <>
                  <BsGrid />
                  <span>Grid View</span>
                </>
              )}
            </Button>
          </div>
        </div>
        
        <div style={{
          backgroundColor: '#fff',
          boxShadow: '0px 0px 20px 5px rgba(0, 0, 0, 0.1)',
          borderRadius: '12px',
          margin: '20px 0',
          padding: '20px',
        }}>
          {
            loading
              ? (<div
                style={{
                  width: "10",
                  height: "400px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <CircularProgress size={24} color="inherit" />
              </div>)
              : dataType === "grid" ? (
                <div className="data-grid-area sep-grid-table-cards">
                  {refUsers?.refferedUsers?.map((data) => (
                    <CustomerCard refUser={data} />
                  ))}
                </div>
              ) : <CustomerTable refUsers={refUsers} />
              
          }
        </div>
          

      </AffiliateLayout>
    </>
  );
}
