import { getStatisticData } from "backendServices/ApiCalls";
import React, { useEffect, useState } from "react";
import ReactHighcharts from "react-highcharts";
import { useTranslation } from "react-i18next";

export function MessageChart() {
  const [timeFrame, setTimeFrame] = useState("daily");
  const [data, setData] = useState([]);
  const [selectedType, setSelectedType] = useState("all"); // New state for type selection
  const types = [
    { value: "all", label: "All" },
    { value: "fb_prospection", label: "Facebook Prospecting" },
    { value: "ig_prospection", label: "Instagram Prospecting" },
    { value: "birthday", label: "Birthday" },
    { value: "requests", label: "Requests" },
    { value: "fb_crm", label: "Facebook CRM" },
    { value: "ig_crm", label: "Instagram CRM" },
  ];
  const { t } = useTranslation();


  // Fetch messages sent data from the API
  function getMessagesSent(type) {
    let params = { type: type }; // Use the passed type parameter
    getStatisticData(
      params,
      (response) => {
        if (response.data.statistics) {
          setData(response.data.statistics);
        }
      },
      (error) => {
        console.error(error); // Handle error appropriately
      }
    );
  }

 
  useEffect(() => {
    getMessagesSent(selectedType);
  }, [selectedType, timeFrame]);
// Helper function to transform data based on the selected timeframe
const transformDataForChart = () => {
  if (timeFrame === "daily") {
    const groupedData = data.reduce((acc, item) => {
      const day = new Date(item.created_at).toLocaleString("en-US", {
        day: "numeric",
        month: "short",
      });
      if (!acc[day]) acc[day] = 0;
      acc[day] += item.message_count;
      return acc;
    }, {});

    return Object.entries(groupedData);
  } else if (timeFrame === "weekly") {
    const groupedData = data.reduce((acc, item) => {
      const date = new Date(item.created_at);
      const weekStart = new Date(date.setDate(date.getDate() - date.getDay()));
      const weekEnd = new Date(date.setDate(weekStart.getDate() + 6));
      const formattedWeek = `${weekStart.getDate()}-${weekEnd.getDate()} ${weekStart.toLocaleString("en-US", { month: "short" })}`;
      if (!acc[formattedWeek]) acc[formattedWeek] = 0;
      acc[formattedWeek] += item.message_count;
      return acc;
    }, {});
    return Object.entries(groupedData);
  } else if (timeFrame === "monthly") {
    const groupedData = data.reduce((acc, item) => {
      const month = new Date(item.created_at).toLocaleString("en-US", {
        month: "long",
      });
      if (!acc[month]) acc[month] = 0;
      acc[month] += item.message_count;
      return acc;
    }, {});
    return Object.entries(groupedData);
  }
};
  // Chart configuration
  const config = {
    chart: {
      type: "column",
    },
    title: {
      text: "Messages Sent",
    },
    subtitle: {
      text: "Select timeframe to view the messages sent",
    },
    xAxis: {
      type: "category",
      labels: {
        rotation: -45,
        style: {
          fontSize: "13px",
          fontFamily: "Verdana, sans-serif",
        },
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "Messages Sent",
      },
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      pointFormat: "Messages: <b>{point.y}</b>",
    },
    series: [
      {
        name: "Messages",
        data: transformDataForChart(),
      },
    ],
  };

  // Event handler for timeframe select box change
  const handleTimeFrameChange = (e) => {
    setTimeFrame(e.target.value);
  };

  // Event handler for type select box change
  const handleTypeChange = (e) => {
    setSelectedType(e.target.value);
  };

  return (
    <div className="sp-stats-outer">
      <span className="sp-title"> {t("pages.title.Messages")}</span>
      <div className="sp-stats-main">
        <div className="sp-stats-selectWrap">
          <select onChange={handleTypeChange} value={selectedType}>
            {types.map((type) => (
              <option key={type.value} value={type.value}>
                {type.label}
              </option>
            ))}
          </select>

          <select onChange={handleTimeFrameChange} value={timeFrame}>
          <option value="daily">{t("pages.title.Daily")}</option>
            <option value="weekly">{t("pages.title.Weekly")}</option>
            <option value="monthly">{t("pages.title.Monthly")}</option>
          </select>
          </div>
        <ReactHighcharts config={config} />
      </div>
    </div>
  );
}
